import { useApolloClient } from "@apollo/client";
import { Box, Stack as MUIStack, Switch } from "@mui/material";
import {
  Button,
  ContextualSaveBar,
  Icon,
  Label,
  LegacyCard,
  LegacyStack,
  Link,
  Pagination,
  ResourceItem,
  ResourceList,
  Scrollable,
  Select,
  Spinner,
  Text,
  TextField,
  Thumbnail,
} from "@shopify/polaris";
import { CancelSmallMinor, HideMinor, LockMajor, SearchMinor, ViewMinor } from "@shopify/polaris-icons";
import { SELLING_PLAN_GROUP_HIDDEN_TAG, SELLING_PLAN_GROUP_TRENDING_LISTS_TAG } from "@smartrr/shared/constants";
import { FeatureEnum } from "@smartrr/shared/entities/AccountPlan";
import { ICustomerPortalTheme } from "@smartrr/shared/entities/CustomerPortalTheme";
import { ISmartrrTrendingListsConfig } from "@smartrr/shared/entities/SellingPlanGroup";
import { TrendingHeart } from "@smartrr/shared/icons/Aquarius/TrendingHeart";
import {
  SellingPlanCategory,
  SellingPlanGroupInput,
  SellingPlanInput,
  SellingPlanInterval,
  SellingPlanPricingPolicyAdjustmentType,
  SellingPlanPricingPolicyInput,
} from "@smartrr/shared/shopifyGraphQL/api";
import {
  mutationShopifyCreateSellingPlanGroup,
  mutationShopifyUpdateSellingPlanGroup,
  querySellingPlanGroupProducts,
  querySellingPlanGroupVariants,
  queryShopifySellingPlanGroup,
} from "@smartrr/shared/shopifyGraphQL/sellingPlans";
import { getShopifyThemes } from "@smartrr/shared/shopifyRest/theme";
import { defaultCustomerPortalThemeConfig } from "@smartrr/shared/themes/defaultThemeConfig";
import { ensureShopifyGid } from "@smartrr/shared/utils/ensureShopifyGid";
import { filterNil } from "@smartrr/shared/utils/filterUndefined";
import { isValidImageUrl } from "@smartrr/shared/utils/isValidImageUrl";
import { MAX_PAGE_SIZE } from "@smartrr/shared/utils/paginatedQuery";
import { ValidSortMethods, sortProductsListByMethod } from "@smartrr/shared/utils/sortBy";
import {
  getVariantImageFromPurchasableMap,
  useVariantShopifyIDToPurchasableMap,
  useVariantToPurchasableMap,
} from "@smartrr/shared/utils/useVariantToPurchasableMap";
import config from "@vendor-app/config";
import { cloneDeep, differenceWith, flatten, isEmpty, isEqual, last, omit } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { v4 } from "uuid";

import { BrowseProductsModal } from "@vendor-app/app/_sharedComponents/BrowseProductsModal";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import {
  loadCustomerPortalTheme,
  updateCustomerPortalTheme,
} from "@vendor-app/app/_state/actionCreators/customerPortalTheme";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { useRestClient } from "@vendor-app/app/AdminRoute/components/auth/RestProviderWrapper";
import {
  addProductsToGroup,
  addVariantsToGroup,
  bustTrendingListsCache,
  removeProductsFromGroup,
  removeVariantsFromGroup,
} from "@vendor-app/utils/sellingPlanGroupProductManagement";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

import { PricingPolicies } from "./PricingPolicies";
import TrendingImage from "./TrendingNotAvailable.png";
import { PaginationContainer } from "../../AdminSubscriptionDetailsRoute/libs";
import { isFeatureAvailable } from "../../components/authorization/featureAccess";
import { DEFAULT_PAGE_SIZE, emptySellingPlan, emptySellingPlanGroup, productSortingOptions } from "../constants";
import {
  determineSellingPlanOperations,
  formatSellingPlanGroupQueryResponse,
  sellingPlanToSellingPlanInput,
} from "../utils";
import { isDefaultVariant } from "@smartrr/shared/utils/isDefaultVariant";
import { formatProductAndVariantNameForDisplay } from "@smartrr/shared/utils/displayUtils";
import {
  hasSelectedIdsReachedMaxQuantity,
  PRODUCT_SELECTION_MAX,
} from "../../AdminSellingPlanGroupsRouteFeatureFlagged/components/TrendingList/utils";
import { StatusBadge } from "@vendor-app/app/_sharedComponents/StatusBadge";

const discountPercentageDefault = 10; // 10%

const NotAvaialableContainer = styled.div`
  display: flex;
`;

const NotAvaialableTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`;

const VariantOrProductTitle = styled.span.attrs((props: { variation: "strong" }) => ({ ...props }))`
  display: block;
  font-weight: ${props => (props.variation === "strong" ? "bold" : "normal")};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 300px;
  @media only screen and (max-width: 880px) {
    width: 265px;
  }
  @media only screen and (max-width: 830px) {
    width: 28vw;
  }
  @media only screen and (max-width: 780px) {
    width: 24vw;
  }
  @media only screen and (max-width: 768px) {
    width: 42vw;
  }
  @media only screen and (max-width: 550px) {
    width: 26vw;
  }
`;

const ProductAndVariantTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LinkWrapper = styled.div`
  .Polaris-Link {
    display: flex;
    font-size: 12px;
  }
`;

const CardTitle = styled.div`
  .Polaris-Text--headingXs {
    color: #6d7175;
    margin-bottom: 5px;
    text-transform: uppercase;
  }
`;

const createNewSellingPlan = () => {
  const newSP: SellingPlanInput = {
    ...emptySellingPlan,
    name: `Smartrr Trending list Subscription Program`,
    options: ["Smartrr Trending list Subscription Program"],
    deliveryPolicy: { recurring: { interval: SellingPlanInterval.Day, intervalCount: 1 } },
    billingPolicy: { recurring: { interval: SellingPlanInterval.Day, intervalCount: 1 } },
    pricingPolicies: [
      {
        fixed: {
          adjustmentType: SellingPlanPricingPolicyAdjustmentType.Percentage,
          adjustmentValue: {
            percentage: discountPercentageDefault,
          },
        },
      },
    ],
  };
  return newSP;
};

const createNewSellingPlanGroup = () => {
  const uniqueId = v4();
  return {
    ...emptySellingPlanGroup,
    name: `${SELLING_PLAN_GROUP_HIDDEN_TAG}${SELLING_PLAN_GROUP_TRENDING_LISTS_TAG}[${uniqueId}]`,
    options: ["Trending-lists"],
    merchantCode: `${SELLING_PLAN_GROUP_HIDDEN_TAG}${SELLING_PLAN_GROUP_TRENDING_LISTS_TAG}[${uniqueId}]`,
    description: "Smartrr trending",
  };
};

export const TrendingListsContainer = (): JSX.Element => {
  const apolloClient = useApolloClient();
  const { addToast } = useToast();
  const restClient = useRestClient();
  const user = useSmartrrVendorSelector(state => state.auth.user);
  const areProductsLoading = useSmartrrVendorSelector(state => state.purchasables.isLoadingAdditionaly);
  const activePlan = useSmartrrVendorSelector(state => state.accountPlans.activePlan);
  const theme = useSmartrrVendorSelector(state => state.customerPortalTheme.customerPortalTheme);
  const themeValues = theme.modernThemeSettings?.sectionsOrder ? theme : defaultCustomerPortalThemeConfig;

  const [trendingListsConfig, setTrendingListsConfig] = useState<ISmartrrTrendingListsConfig | null>(null);
  const [mainThemeId, setMainThemeId] = useState<number>();
  const [isLoading, setIsLoading] = useState(true);

  const [sellingPlanGroup, setTrendingConfigInput] = useState<SellingPlanGroupInput | null>(
    createNewSellingPlanGroup()
  );
  const [sellingPlans, setSellingPlans] = useState<SellingPlanInput[]>([createNewSellingPlan()]);

  const fetchTrendingLists = async (bustCache?: boolean) => {
    setIsLoading(true);
    const trendingListRes = await typedFrontendVendorApi.getReq("/trending-lists-config", {
      query: {
        cache: bustCache ? "false" : "true",
      },
    });

    if (trendingListRes.type === "error") {
      addToast("Error fetching trending lists");
      return;
    }

    setTrendingListsConfig(trendingListRes.body[0] || null);
    const id = trendingListRes.body[0]?.shopifyId || null;

    if (id) {
      const res = await queryShopifySellingPlanGroup(apolloClient, id);
      const { sellingPlanGroup } = res;
      if (!sellingPlanGroup) {
        addToast("There was an error fetching trending lists configuration");
        setIsLoading(false);
        return;
      }

      const { sellingPlanGroupInput, sellingPlanInputs } = formatSellingPlanGroupQueryResponse(sellingPlanGroup);
      setTrendingConfigInput(sellingPlanGroupInput);
      setSellingPlans(sellingPlanInputs);
    }

    setIsLoading(false);
  };

  const getMainThemeId = useCallback(async () => {
    const res = await getShopifyThemes(restClient);
    if (res.type === "error" || !res.body.themes?.find(t => t.role === "main")) {
      addToast("Error getting main theme id");
      return;
    }
    const mainTheme = res.body.themes.find(t => t.role === "main")!;
    setMainThemeId(mainTheme.id);
  }, []);

  useEffect(() => {
    fetchTrendingLists();
    getMainThemeId();
  }, []);

  const onSave = async () => {
    await fetchTrendingLists(true);
  };

  if (!isFeatureAvailable(FeatureEnum.TRENDING, user, activePlan)) {
    return (
      <React.Fragment>
        <Text variant="headingLg" as="p" fontWeight="semibold">
          Trending lists
        </Text>
        <div style={{ marginTop: "1.25rem" }}>
          <LegacyCard>
            <NotAvaialableContainer>
              <img src={TrendingImage} width="250" height="226" alt="Not available" />
              <NotAvaialableTextContainer>
                <Text variant="headingMd" as="h2">
                  <MUIStack direction="row" sx={{ svg: { fill: "var(--p-color-icon)" } }}>
                    <Icon source={LockMajor} />
                    <span style={{ marginLeft: "10px" }}>Trending lists unavailable on current plan</span>
                  </MUIStack>
                </Text>
                <p>
                  Upgrade to Excel to curate a list and use to showcase influencer picks, new arrivals, and
                  limited edition drops in your shop&apos;s Account Portal.
                </p>
                <div>
                  <Button primary url="/admin/smartrr-account">
                    💎 Upgrade to Excel
                  </Button>
                </div>
              </NotAvaialableTextContainer>
            </NotAvaialableContainer>
          </LegacyCard>
        </div>
      </React.Fragment>
    );
  }

  if (isLoading) {
    return (
      <LegacyStack vertical alignment="center">
        <Spinner />
      </LegacyStack>
    );
  }

  return (
    <React.Fragment>
      <Text variant="headingLg" as="p" fontWeight="semibold">
        Trending lists
      </Text>
      <div style={{ marginTop: "1.25rem" }}>
        <LegacyCard>
          <NotAvaialableContainer>
            <img src={TrendingImage} width="250" height="226" alt="Not available" />
            <NotAvaialableTextContainer>
              <Text variant="headingMd" as="h2">
                <MUIStack direction="column">
                  <CardTitle>
                    <Text variant="headingXs" as="h3">
                      Customer account portal
                    </Text>
                    <span>Trending lists</span>
                  </CardTitle>
                </MUIStack>
              </Text>
              <p>
                Curate up a list and use to showcase influencer picks, new arrivals, and limited edition drops in
                your shop&apos;s Customer Account Portal. Add products below enable section visibility under
                Theming &gt; Portal Settings &gt; Trending.
              </p>
            </NotAvaialableTextContainer>
          </NotAvaialableContainer>
        </LegacyCard>
      </div>
      <div style={{ marginTop: "1.25rem" }}>
        {sellingPlanGroup ? (
          <TrendingListsData
            sellingPlanGroupId={trendingListsConfig?.shopifyId}
            sellingPlanGroup={sellingPlanGroup}
            sellingPlan={sellingPlans[0]}
            onSave={onSave}
            theme={themeValues}
            mainThemeId={mainThemeId!}
            areProductsLoading={areProductsLoading}
          />
        ) : null}
      </div>
    </React.Fragment>
  );
};

export interface TrendingListsDataProps {
  sellingPlanGroupId?: string | null;
  sellingPlanGroup: SellingPlanGroupInput;
  sellingPlan: SellingPlanInput;
  onSave: () => Promise<void>;
  theme: ICustomerPortalTheme;
  mainThemeId: number;
  areProductsLoading: boolean;
}

const TrendingListsData = ({
  sellingPlanGroupId: initialSellingPlanGroupId,
  sellingPlanGroup: initialSellingPlanGroup,
  sellingPlan: initialSellingPlan,
  onSave: onSaveChanges,
  theme,
  mainThemeId,
  areProductsLoading,
}: TrendingListsDataProps): JSX.Element => {
  const apolloClient = useApolloClient();
  const { addToast } = useToast();
  const dispatch = useSmartrrVendorDispatch();
  const { purchasables } = useSmartrrVendorSelector(state => state.purchasables);
  const variantToPurchasableMap = useVariantToPurchasableMap(purchasables);
  const variantShopifyIdToPurchaseableMap = useVariantShopifyIDToPurchasableMap(purchasables);

  const trendingSectionThemeValues = theme.modernThemeSettings?.sections.trending;

  const [hasChanges, setHasChanges] = useState(false);
  const [sortValue, setSortValue] = useState<ValidSortMethods>(
    trendingSectionThemeValues?.sortMethod || ValidSortMethods.MANUAL
  );

  const [sellingPlanGroup, setSellingPlanGroup] = useState<SellingPlanGroupInput>({
    ...cloneDeep(initialSellingPlanGroup),
    appId: config.shopify.appId,
  });
  const [sellingPlan, setSellingPlan] = useState<SellingPlanInput>(initialSellingPlan);
  const [sellingPlanGroupId, setSellingPlanGroupId] = useState<string | undefined | null>(
    initialSellingPlanGroupId
  );

  const [linkedProductIds, setLinkedProductIds] = useState<string[]>([]);
  const [linkedVariantIds, setLinkedVariantIds] = useState<string[]>([]);

  const [productIds, setProductIds] = useState(linkedProductIds);
  const [variantIds, setVariantIds] = useState<string[]>([]);

  // link products/variants state items
  const [showProductOptionsModal, setShowProductOptionsModal] = useState(false);
  const [modalSelectedProductOrVariantIds, setModalSelectedProductOrVariantIds] = useState<string[]>([]);
  const [isProductOrVariantLinkedLoading, setIsProductOrVariantLinkedLoading] = useState(false);

  // Modal search
  const [filterProductsText, setFilterProductsText] = useState<string>("");
  // Selected products search
  const [searchText, setSearchText] = useState<string>("");

  const [isSaving, setIsSaving] = useState(false);

  const [pageNumber, setPageNumber] = useState<number>(0);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [disableConfirm] = useState<boolean>(false);

  const [listHeading, setListHeading] = useState<string | null>(trendingSectionThemeValues?.heading ?? null);
  const [listCaption, setListCaption] = useState<string | null>(trendingSectionThemeValues?.caption ?? null);
  const [showHeart, setShowHeart] = useState<boolean | null>(trendingSectionThemeValues?.showHeart ?? null);
  const [imgUrl, setImgUrl] = useState<string | null>(trendingSectionThemeValues?.imgUrl ?? null);
  const [urlValidationError, setUrlValidationError] = useState<boolean>(false);

  const applyValuesToTheming = useCallback(async () => {
    await dispatch(
      updateCustomerPortalTheme({
        updatedThemeProperties: {
          modernThemeSettings: {
            ...theme.modernThemeSettings!,
            sections: {
              ...theme.modernThemeSettings?.sections!,
              trending: {
                display: trendingSectionThemeValues?.display!,
                caption: listCaption!,
                heading: listHeading!,
                showHeart: showHeart!,
                imgUrl: imgUrl!,
                sortMethod: sortValue!,
              },
            },
          },
        },
        themeId: mainThemeId,
        addToast,
      })
    );
    await dispatch(loadCustomerPortalTheme());
  }, [imgUrl, listCaption, listHeading, mainThemeId, showHeart, sortValue, theme, trendingSectionThemeValues]);

  const onSave = useCallback(async () => {
    setIsSaving(true);

    if (!isValidImageUrl(imgUrl!)) {
      setUrlValidationError(true);
      addToast("Please enter a valid image url");
      setIsSaving(false);
      return;
    }
    // Removing error in case
    setUrlValidationError(false);

    await applyValuesToTheming();

    if (sellingPlanGroupId) {
      const groupToSave = omit(sellingPlanGroup, ["id", "sellingPlans"]);
      const { sellingPlansToCreate, sellingPlansToDelete, sellingPlansToUpdate } = determineSellingPlanOperations(
        [sellingPlan],
        [initialSellingPlan],
        SellingPlanCategory.Other
      );

      const res = await mutationShopifyUpdateSellingPlanGroup(
        sellingPlanGroupId,
        {
          ...groupToSave,
          sellingPlansToCreate,
          sellingPlansToUpdate,
          sellingPlansToDelete: sellingPlansToDelete.map(p => p.id!),
        },
        apolloClient
      );

      if (res.type === "success") {
        if (res.body.data?.sellingPlanGroupUpdate?.userErrors.length) {
          setIsSaving(false);
          addToast(
            `Errors updating trending configuration: \n${(
              res.body.data?.sellingPlanGroupUpdate?.userErrors || []
            ).join("\n")}`
          );
          return;
        }

        const updatedSellingPlan =
          res.body.data?.sellingPlanGroupUpdate?.sellingPlanGroup?.sellingPlans.edges[0]?.node;
        if (updatedSellingPlan) {
          const updatedSellingPlanInput = sellingPlanToSellingPlanInput(updatedSellingPlan);
          setSellingPlan(updatedSellingPlanInput);
        }
      } else {
        addToast(
          `Errors updating trending configuration: ${
            res.body.errors?.map(error => error.message).join(", ") || ""
          }`
        );
        setIsSaving(false);
      }
    } else {
      const res = await mutationShopifyCreateSellingPlanGroup(
        {
          ...sellingPlanGroup,
          sellingPlansToCreate: [
            {
              ...sellingPlan,
              category: SellingPlanCategory.Other,
            },
          ],
        },
        apolloClient
      );

      if (res.type === "success") {
        const { data } = res.body;
        if (data?.sellingPlanGroupCreate?.userErrors.length) {
          setIsSaving(false);
          addToast(
            `Errors updating trending configuration: \n${(
              res.body.data?.sellingPlanGroupCreate?.userErrors || []
            ).join("\n")}`
          );
          return;
        }
      }
    }

    await onSaveChanges();
    setIsSaving(false);
    setHasChanges(false);
    addToast("Trending list updated");
  }, [
    addToast,
    apolloClient,
    applyValuesToTheming,
    imgUrl,
    initialSellingPlan,
    onSaveChanges,
    sellingPlan,
    sellingPlanGroup,
    sellingPlanGroupId,
  ]);

  const onDiscard = useCallback(() => {
    setSellingPlanGroup({ ...initialSellingPlanGroup });
    setSellingPlan(cloneDeep(initialSellingPlan));
    setHasChanges(false);
  }, [initialSellingPlanGroup, initialSellingPlan]);

  const onEditProductsAndVariantsClick = useCallback(async () => {
    if (sellingPlanGroupId) {
      setShowProductOptionsModal(true);
      setModalSelectedProductOrVariantIds([...variantIds, ...productIds]);
    } else {
      const res = await mutationShopifyCreateSellingPlanGroup(
        {
          ...sellingPlanGroup,
          sellingPlansToCreate: [
            {
              ...sellingPlan,
              category: SellingPlanCategory.Other,
            },
          ],
        },
        apolloClient
      );

      if (res.type === "success") {
        const { data } = res.body;
        if (data?.sellingPlanGroupCreate?.userErrors.length) {
          addToast(
            `Errors updating trending configuration: \n${(
              res.body.data?.sellingPlanGroupCreate?.userErrors || []
            ).join("\n")}`
          );
        } else {
          setSellingPlanGroupId(data?.sellingPlanGroupCreate?.sellingPlanGroup?.id);
          setShowProductOptionsModal(true);
          setModalSelectedProductOrVariantIds([...variantIds, ...productIds]);
        }
      }
    }
  }, [sellingPlanGroupId, productIds, variantIds]);

  const onEditProducts = useCallback(
    async (ids: string[]) => {
      if (!sellingPlanGroup) {
        return;
      }

      if (!sellingPlanGroupId) {
        addToast("Please click save first before selecting products");
        return;
      }
      const toAdd = ids.filter(id => !productIds.includes(id));
      const toRemove = productIds.filter(id => !ids.includes(id));

      if (toAdd.length) {
        await addProductsToGroup(apolloClient, sellingPlanGroupId, toAdd);
        addToast("Product(s) added to trending list");
      }

      if (toRemove.length) {
        await removeProductsFromGroup(apolloClient, sellingPlanGroupId, toRemove);
        addToast("Product(s) removed from trending list");
      }

      const productIdsToSet = (ids: string[]) => ids.filter(id => !toRemove.includes(id)).concat(toAdd);
      setProductIds(productIdsToSet);
      setLinkedProductIds(productIdsToSet);

      setShowProductOptionsModal(false);
    },
    [sellingPlanGroup, purchasables, productIds, sellingPlanGroupId]
  );

  const onEditVariants = useCallback(
    async (ids: string[]) => {
      if (!sellingPlanGroup) {
        return;
      }

      if (!sellingPlanGroupId) {
        addToast("Please click save first before selecting products");
        return;
      }
      const toAdd = ids.filter(id => !variantIds.includes(id));
      const toRemove = variantIds.filter(id => !ids.includes(id));

      if (toAdd.length) {
        await addVariantsToGroup(apolloClient, sellingPlanGroupId, toAdd);
        addToast("Variant(s) added to trending list");
      }

      if (toRemove.length) {
        await removeVariantsFromGroup(apolloClient, sellingPlanGroupId, toRemove);
        addToast("Variant(s) removed from trending list");
      }

      const variantIdsToSet = (ids: string[]) => ids.filter(id => !toRemove.includes(id)).concat(toAdd);
      setVariantIds(variantIdsToSet);

      setShowProductOptionsModal(false);
    },
    [sellingPlanGroup, purchasables, variantIds, sellingPlan, sellingPlanGroupId]
  );

  const onEditProductsOrVariantsCb = useCallback(async () => {
    setIsProductOrVariantLinkedLoading(true);
    setFilterProductsText("");

    await onEditProducts(modalSelectedProductOrVariantIds.filter(p => !p.includes("ProductVariant")));
    await onEditVariants(modalSelectedProductOrVariantIds.filter(p => p.includes("ProductVariant")));

    bustTrendingListsCache();
    await paginatedVariantsQuery(null, true);
    setIsProductOrVariantLinkedLoading(false);
  }, [modalSelectedProductOrVariantIds, onEditProducts, onEditVariants]);

  useEffect(() => {
    if (
      (trendingSectionThemeValues &&
        !isEqual(trendingSectionThemeValues, {
          display: trendingSectionThemeValues?.display,
          caption: listCaption,
          heading: listHeading,
          showHeart,
          imgUrl,
          sortMethod: sortValue,
        })) ||
      (!trendingSectionThemeValues && (listHeading || listCaption || imgUrl))
    ) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  }, [trendingSectionThemeValues, listCaption, listHeading, showHeart, imgUrl]);

  const onUpdateSellingPlan = (
    sellingPlan: SellingPlanInput,
    pricingPolicies: SellingPlanPricingPolicyInput[]
  ) => {
    setHasChanges(true);
    setSellingPlan({ ...sellingPlan, pricingPolicies });
  };

  const handleRemoveProductsAndVariants = async (vntIds: string[], productId?: string) => {
    setIsProductOrVariantLinkedLoading(true);

    setModalSelectedProductOrVariantIds([
      ...productIds.filter(id => id !== productId),
      ...variantIds.filter(id => !vntIds.includes(id)),
    ]);

    await onEditProducts(productIds.filter(id => id !== productId));
    await onEditVariants(variantIds.filter(id => !vntIds.includes(id)));

    bustTrendingListsCache();
    await paginatedVariantsQuery(null, true);
    setIsProductOrVariantLinkedLoading(false);
  };

  const paginatedVariantsQuery = async (newCursor: string | null, bustCache?: boolean) => {
    const paginatedVariantIds: string[] = [];
    const paginatedProductIds = new Set<string>();

    setIsProductOrVariantLinkedLoading(true);
    const res = await querySellingPlanGroupVariants(
      apolloClient,
      ensureShopifyGid("SellingPlanGroup", sellingPlanGroupId!),
      DEFAULT_PAGE_SIZE,
      newCursor,
      bustCache
    );

    if (res.type === "error" || !res.body.data.sellingPlanGroup) {
      return {
        lastCursor: null,
        hasNextPage: false,
      };
    }

    const {
      edges,
      pageInfo: { hasNextPage },
    } = res.body.data.sellingPlanGroup.productVariants;

    const nodes = edges.map(({ node }: any) => node) || [];
    for (const node of nodes) {
      paginatedVariantIds.push(node.id);
      paginatedProductIds.add(node.product.id);
    }

    setLinkedVariantIds(paginatedVariantIds);
    setLinkedProductIds([...paginatedProductIds]);
    setIsProductOrVariantLinkedLoading(false);

    return { lastCursor: last(edges)?.cursor, hasNextPage };
  };

  const loadProductsAndVariants = async (
    sellingPlanGroupId?: string,
    productsCursor?: string | null,
    variantsCursor?: string | null
  ) => {
    const productIds: string[] = [];
    const variantIds: string[] = [];
    const [productsRes, variantsRes] = await Promise.all([
      querySellingPlanGroupProducts(apolloClient, sellingPlanGroupId!, MAX_PAGE_SIZE, productsCursor),
      querySellingPlanGroupVariants(apolloClient, sellingPlanGroupId!, MAX_PAGE_SIZE, variantsCursor),
    ]);

    if (
      variantsRes.type === "error" ||
      !variantsRes.body.data.sellingPlanGroup ||
      productsRes.type === "error" ||
      !productsRes.body.data.sellingPlanGroup
    ) {
      return {
        productIds: [],
        variantIds: [],
      };
    }

    const variantsEdges = variantsRes.body.data.sellingPlanGroup.productVariants.edges;
    const productsEdges = productsRes.body.data.sellingPlanGroup.products.edges;

    const variantNodes = variantsEdges.map(({ node }) => node) || [];
    const productNodes = productsEdges.map(({ node }) => node) || [];

    for (const node of variantNodes) {
      variantIds.push(node.id);
    }
    for (const node of productNodes) {
      productIds.push(node.id);
    }

    return {
      productIds,
      variantIds,
    };
  };

  useEffect(() => {
    if (sellingPlanGroupId) {
      paginatedVariantsQuery(null, true);
      loadProductsAndVariants(sellingPlanGroupId).then(({ productIds, variantIds }) => {
        setProductIds(productIds);
        setVariantIds(variantIds);
      });
    }
  }, []);

  const linkedProducts = filterNil(
    linkedProductIds
      .filter(productId => productIds.includes(productId))
      .map(productId => purchasables.find(purch => purch.shopifyId === productId))
  );

  const linkedVariantsToShow = filterNil(
    differenceWith(
      linkedVariantIds,
      flatten(purchasables.filter(purch => productIds.includes(purch.shopifyId!)).map(purch => purch.vnts)),
      (variantId, purch) => purch?.shopifyId === variantId
    )
  );

  const linkedVariantsToPurchasable = filterNil(
    flatten(
      purchasables.map(purch =>
        purch.vnts?.map(vnt => ({
          ...vnt,
          purchasableName: purch.purchasableName,
        }))
      )
    ).filter(purch => linkedVariantsToShow.includes(purch?.shopifyId!))
  );

  const isProducts = linkedVariantsToPurchasable.length || linkedVariantIds.length;

  const reachedMaxQuantity = (addedItem: string): boolean => {
    const reachedMax = hasSelectedIdsReachedMaxQuantity(
      addedItem,
      modalSelectedProductOrVariantIds,
      variantShopifyIdToPurchaseableMap
    );
    if (reachedMax) {
      addToast("Maximum selected products reached");
      return true;
    }
    return false;
  };

  const purchasableOptions = useMemo(() => {
    const filteredAndSortedPurchasables = purchasables.filter(p => !!p.shopifyId && !p.isDraftOrArchived).sort();

    if (filterProductsText) {
      return filteredAndSortedPurchasables.filter(p =>
        p.purchasableName.toLocaleLowerCase().includes(filterProductsText.toLocaleLowerCase())
      );
    }

    return filteredAndSortedPurchasables;
  }, [purchasables, filterProductsText]);

  const totalPages = Math.ceil(purchasableOptions.length / MAX_PAGE_SIZE);

  return (
    <React.Fragment>
      {hasChanges ? (
        <ContextualSaveBar
          message="Unsaved changes"
          saveAction={{ onAction: onSave, loading: isSaving, content: "Save changes", disabled: false }}
          discardAction={{ content: "Discard changes", onAction: onDiscard }}
        />
      ) : null}
      <React.Fragment>
        <LegacyCard>
          <LegacyCard.Section>
            <MUIStack direction="row" justifyContent="space-between">
              <Text variant="headingMd" as="h2">
                {isEmpty(listHeading) ? "Trending list" : listHeading}
              </Text>

              <MUIStack
                direction="row"
                spacing="5px"
                alignItems="center"
                sx={{
                  svg: {
                    fill: "var(--p-color-text-subdued)",
                  },
                }}
              >
                {trendingSectionThemeValues?.display ? (
                  <LinkWrapper>
                    <Link url="/admin/configure/theming" external removeUnderline>
                      <Icon source={ViewMinor} />
                      <p style={{ color: "var(--p-color-text-interactive)", marginLeft: "7px" }}>
                        Visible in Account Portal
                      </p>
                    </Link>
                  </LinkWrapper>
                ) : (
                  <React.Fragment>
                    <Icon source={HideMinor} />
                    <p style={{ color: "var(--p-color-text-subdued)" }}>Hidden in Account Portal</p>
                  </React.Fragment>
                )}
              </MUIStack>
            </MUIStack>
          </LegacyCard.Section>
          <LegacyCard.Section>
            <MUIStack spacing="30px">
              <TextField
                label="Heading"
                value={listHeading ?? ""}
                placeholder="Example: Ava Price's Black Friday Faves"
                onChange={e => setListHeading(e)}
                autoComplete="off"
                max={50}
              />
              <TextField
                label="Caption text"
                value={listCaption ?? ""}
                onChange={e => setListCaption(e)}
                placeholder='Example: "My current makeup go-tos. Day time application tip: Apply one pump on my hands, warm it up by rubbing my hands together and apply directly on my face!"'
                multiline={4}
                max={250}
                autoComplete="off"
              />
              <MUIStack justifyContent="space-between" direction="row">
                <MUIStack direction="column">
                  <div>
                    <p style={{ fontSize: "14px", lineHeight: "20px" }}>Image</p>
                    <Text variant="bodySm" as="p">
                      Recommended size: 300x300px .jpeg, .png, or .svg
                    </Text>
                  </div>
                  <div style={{ marginTop: "3px" }}>
                    <TextField
                      label="Media url"
                      value={imgUrl ?? ""}
                      onChange={e => setImgUrl(e)}
                      placeholder={"https://www.website.com/image.jpg"}
                      error={urlValidationError ? "Invalid image url" : ""}
                      autoComplete="off"
                    />
                  </div>
                  <MUIStack
                    spacing="7px"
                    direction="row"
                    sx={{
                      marginTop: "10px",
                      "& svg": {
                        width: "16px",
                        height: "16px",
                      },
                    }}
                  >
                    <TrendingHeart />
                    <p style={{ fontSize: "14px" }}>Show heart?</p>
                    <Switch
                      value={false}
                      size="small"
                      onChange={e => setShowHeart(prev => !prev)}
                      defaultChecked={showHeart ?? false}
                    />
                  </MUIStack>
                </MUIStack>
                <MUIStack textAlign="end">
                  <p className="Polaris-Text--subdued" style={{ fontSize: "14px" }}>
                    Preview
                  </p>
                  <Box
                    sx={{
                      display: "block !important",
                      background: `url(${imgUrl})`,
                      height: "150px",
                      width: "150px",
                      backgroundColor: "#E4E4E4",
                      backgroundPosition: "center center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      borderRadius: "137px",
                      position: "relative",
                      "& svg": {
                        position: "absolute",
                        bottom: "0px",
                        right: "0px",
                      },
                    }}
                  >
                    {!!showHeart && <TrendingHeart />}
                  </Box>
                </MUIStack>
              </MUIStack>
            </MUIStack>
          </LegacyCard.Section>
          <LegacyCard.Section>
            <MUIStack
              direction="column"
              justifyContent="space-between"
              sx={{
                ".Polaris-FormLayout__Item": {
                  width: "285px",
                  "& > *": {
                    width: "100%",
                  },
                },
              }}
            >
              <Label id={""}>List discount</Label>
              <PricingPolicies
                sellingPlan={sellingPlan}
                fixedPolicyOnly={true}
                onPoliciesUpdate={pricingPolicies => onUpdateSellingPlan(sellingPlan, pricingPolicies)}
              />
            </MUIStack>
          </LegacyCard.Section>
          <LegacyCard.Section>
            <MUIStack
              justifyContent="space-between"
              alignItems="end"
              direction="row"
              sx={{
                ".Polaris-Button": {
                  marginLeft: "4px",
                },
                ".Polaris-Select": {
                  width: "210px",
                  marginLeft: "10px",
                },
              }}
            >
              <TextField
                autoComplete="off"
                label={`List products (max ${PRODUCT_SELECTION_MAX})`}
                placeholder="Search products"
                prefix={<Icon source={SearchMinor} color="base" />}
                value={searchText}
                onChange={setSearchText}
              />
              <Button onClick={onEditProductsAndVariantsClick}>Browse</Button>
              <Select
                label="Sort:"
                options={productSortingOptions}
                onChange={selected => {
                  // Compensating
                  if (initialSellingPlan.description === selected) {
                    setHasChanges(false);
                  } else {
                    setHasChanges(true);
                  }
                  setSortValue(
                    Object.values(ValidSortMethods)[
                      Object.values(ValidSortMethods).indexOf(selected as ValidSortMethods) as number
                    ]
                  );
                }}
                value={sortValue}
                labelInline
              />
            </MUIStack>
          </LegacyCard.Section>
          <LegacyCard.Section>
            {isProducts ? (
              <Scrollable
                vertical
                hint
                shadow
                style={{
                  maxHeight: "350px",
                  position: "relative",
                }}
              >
                {isProductOrVariantLinkedLoading || areProductsLoading ? (
                  <LegacyStack distribution="center" alignment="center">
                    <Spinner />
                  </LegacyStack>
                ) : (
                  <React.Fragment>
                    <ResourceList
                      items={sortProductsListByMethod(
                        linkedProducts.filter(p =>
                          p.purchasableName.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
                        ),
                        sortValue
                      )}
                      renderItem={(purchasable, id, productIndex) => {
                        const variantNames = purchasable.vnts
                          ?.filter(
                            vnt =>
                              variantIds.includes(vnt.shopifyId!) && !isDefaultVariant(vnt.purchasableVariantName)
                          )
                          .map(vnt => vnt.purchasableVariantName)
                          .join(", ");
                        return (
                          <ResourceItem
                            key={purchasable.shopifyId || ""}
                            id={purchasable.shopifyId || ""}
                            verticalAlignment="center"
                            onClick={() => undefined}
                          >
                            <LegacyStack alignment="center" distribution="equalSpacing" wrap={false}>
                              <div>
                                <LegacyStack alignment="center" distribution="leading">
                                  <Thumbnail
                                    size="small"
                                    source={purchasable.purchasableImages?.[0] || ""}
                                    alt={purchasable.purchasableName}
                                  />
                                  <ProductAndVariantTitleContainer>
                                    <VariantOrProductTitle variation="strong" title={purchasable.purchasableName}>
                                      {purchasable.purchasableName}
                                    </VariantOrProductTitle>
                                    <VariantOrProductTitle title={variantNames}>
                                      {variantNames}
                                    </VariantOrProductTitle>
                                  </ProductAndVariantTitleContainer>
                                </LegacyStack>
                              </div>
                              <LegacyStack distribution="equalSpacing" spacing="baseTight">
                                <StatusBadge obj={purchasable} />
                                <Button
                                  disabled={isProductOrVariantLinkedLoading}
                                  onClick={() =>
                                    handleRemoveProductsAndVariants(
                                      purchasable.vnts?.map(vnt => vnt.shopifyId!) || [],
                                      purchasable.shopifyId!
                                    )
                                  }
                                  plain
                                  icon={CancelSmallMinor}
                                />
                              </LegacyStack>
                            </LegacyStack>
                          </ResourceItem>
                        );
                      }}
                    />
                    <ResourceList
                      items={linkedVariantsToPurchasable.filter(p =>
                        p.purchasableName.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
                      )}
                      renderItem={variant => {
                        const displayName = formatProductAndVariantNameForDisplay({
                          purchasableName: variant.purchasableName,
                          purchasableVariantName: variant.purchasableVariantName,
                          isActive: variant.isActiveInShopify,
                        });
                        return (
                          <ResourceItem
                            key={variant.shopifyId || ""}
                            id={variant.shopifyId || ""}
                            verticalAlignment="center"
                            onClick={() => undefined}
                          >
                            <LegacyStack alignment="center" distribution="equalSpacing" wrap={false}>
                              <LegacyStack alignment="center" distribution="leading">
                                <Thumbnail
                                  size="small"
                                  source={getVariantImageFromPurchasableMap(variantToPurchasableMap, variant)!}
                                  alt={variant?.purchasableVariantName || ""}
                                />
                                <VariantOrProductTitle variation="strong" title={displayName}>
                                  {displayName}
                                </VariantOrProductTitle>
                              </LegacyStack>
                              <LegacyStack distribution="equalSpacing" spacing="baseTight">
                                <StatusBadge obj={variant} />
                                <Button
                                  disabled={isProductOrVariantLinkedLoading}
                                  onClick={() => handleRemoveProductsAndVariants([variant.shopifyId!])}
                                  plain
                                  icon={CancelSmallMinor}
                                />
                              </LegacyStack>
                            </LegacyStack>
                          </ResourceItem>
                        );
                      }}
                    />
                  </React.Fragment>
                )}
              </Scrollable>
            ) : (
              <Text variant="bodyMd" as="span" color="subdued">
                You have no products added yet.
              </Text>
            )}
          </LegacyCard.Section>
        </LegacyCard>
      </React.Fragment>
      <BrowseProductsModal
        purchasableOptions={purchasableOptions.slice(
          pageNumber * MAX_PAGE_SIZE,
          pageNumber * MAX_PAGE_SIZE + MAX_PAGE_SIZE
        )}
        selectedProductsAndVariants={modalSelectedProductOrVariantIds}
        searchText={filterProductsText}
        title={
          <LegacyStack vertical spacing="extraTight">
            <Text variant="headingLg" as="p">
              Browse products
            </Text>
            <p style={{ fontSize: "14px", lineHeight: "20px" }}>
              Select at most {PRODUCT_SELECTION_MAX} products and their corresponding variants to be featured on a
              trending products list
            </p>
          </LegacyStack>
        }
        open={showProductOptionsModal}
        onClose={() => {
          setShowProductOptionsModal(false);
          setFilterProductsText("");
        }}
        primaryAction={useMemo(
          () => ({
            content: "Confirm",
            onAction: onEditProductsOrVariantsCb,
            loading: isProductOrVariantLinkedLoading,
            disabled: disableConfirm,
          }),
          [onEditProductsOrVariantsCb, isProductOrVariantLinkedLoading]
        )}
        secondaryActions={[
          {
            content: "Cancel",
            onAction() {
              setShowProductOptionsModal(false);
              setFilterProductsText("");
            },
          },
        ]}
        footer={
          <PaginationContainer>
            <Pagination
              hasNext={totalPages > pageNumber + 1}
              hasPrevious={pageNumber != 0}
              label={`Showing ${pageNumber + 1} of ${totalPages || 1}`}
              onNext={() => setPageNumber(prev => prev + 1)}
              onPrevious={() => setPageNumber(prev => prev - 1)}
            />
          </PaginationContainer>
        }
        onSearchChange={text => {
          setFilterProductsText(text);
          //reset page on filter change to avoid blank card
          setPageNumber(0);
        }}
        setSelectedProductAndVariantIds={setModalSelectedProductOrVariantIds}
        reachedMaxQuantity={reachedMaxQuantity}
      />
    </React.Fragment>
  );
};
