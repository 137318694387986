import { FeatureEnum } from "@smartrr/shared/entities/AccountPlan";
import { useSmartrrFlags } from "@smartrr/shared/LaunchDarkly";
import { createLazyFileRoute } from "@tanstack/react-router";

import { ErrorBoundary } from "@vendor-app/app/_sharedComponents/ErrorBoundary";
import { AdminSellingPlanGroupsRoute } from "@vendor-app/app/AdminRoute/AdminSellingPlanGroupsRoute";
import { AdminSellingPlanGroupsRoute as AdminSellingPlanGroupsRouteFeatureFlagged } from "@vendor-app/app/AdminRoute/AdminSellingPlanGroupsRouteFeatureFlagged";
import { AuthorizedRoute } from "@vendor-app/app/AdminRoute/components/authorization/AuthorizedRoute";

export const Route = createLazyFileRoute("/admin/configure/plans/")({
  component: Index,
  errorComponent: ({ error, info }) => <ErrorBoundary error={error} info={info} />,
});

function Index() {
  const { subscriptionProgramsImprovement } = useSmartrrFlags();

  return AuthorizedRoute(
    FeatureEnum.CORE_CONFIGURATION,
    subscriptionProgramsImprovement ? (
      <AdminSellingPlanGroupsRouteFeatureFlagged />
    ) : (
      <AdminSellingPlanGroupsRoute />
    )
  );
}
