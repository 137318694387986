import {
  Button,
  ContextualSaveBar,
  LegacyCard,
  LegacyStack,
  Pagination,
  ResourceList,
  Scrollable,
  Text,
} from "@shopify/polaris";
import {
  ISellingPlanFromZodWithId,
  SellingPlanGroupApiResponse,
} from "@smartrr/shared/entities/SellingPlanGroup";
import { filterNil } from "@smartrr/shared/utils/filterUndefined";
import { MAX_PAGE_SIZE } from "@smartrr/shared/utils/paginatedQuery";
import { useVariantToPurchasableMap } from "@smartrr/shared/utils/useVariantToPurchasableMap";
import { cloneDeep, flatten, isEqual, set } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { BrowseProductsModal } from "@vendor-app/app/_sharedComponents/BrowseProductsModal";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

import { AddProductMajorSVG } from "../../../../_sharedComponents/SVGs/AddProductMajorSVG";
import { Spinner } from "../../../components/elements/Spinner";
import { ILinkedVariantWithPurchaseableName } from "../../constants";
import { NoProductsContainer, PaginationContainer } from "../../libs";
import { PricingPolicies } from "../PricingPolicies";
import {
  SellingPlanGroupProductsListItem,
  SellingPlanGroupVariantToPurchaseableListItem,
} from "../SellingPlanGroupProductsListItem";

export interface AddOnGroupContainerProps {
  sellingPlanGroup: SellingPlanGroupApiResponse;
  onUpdateAddonConfig: (
    sellingPlan: ISellingPlanFromZodWithId,
    productIds: string[],
    variantIds: string[]
  ) => Promise<void>;
}

export function AddOnGroupContainer({
  sellingPlanGroup: initialSellingPlanGroup,
  onUpdateAddonConfig,
}: AddOnGroupContainerProps): React.JSX.Element {
  const { purchasables, isLoading: areProductsLoading } = useSmartrrVendorSelector(state => state.purchasables);

  const variantToPurchasableMap = useVariantToPurchasableMap(purchasables);

  const [sellingPlan, setSellingPlan] = useState<ISellingPlanFromZodWithId>(
    initialSellingPlanGroup.sellingPlans[0]
  );
  const [productIds, setProductIds] = useState(initialSellingPlanGroup.productIds);
  const [variantIds, setVariantIds] = useState<string[]>(initialSellingPlanGroup.productVariantIds);
  const [pageNumber, setPageNumber] = useState<number>(0);

  // link products/variants state items
  const [showProductOptionsModal, setShowProductOptionsModal] = useState(false);
  const [modalSelectedProductOrVariantIds, setModalSelectedProductOrVariantIds] = useState<string[]>([]);
  const [filterProductsText, setFilterProductsText] = useState<string>("");

  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    setHasChanges(
      !isEqual(initialSellingPlanGroup.productIds, productIds) ||
        !isEqual(initialSellingPlanGroup.productVariantIds, variantIds)
    );
  }, [productIds, variantIds, initialSellingPlanGroup.productIds, initialSellingPlanGroup.productVariantIds]);

  useEffect(() => {
    setHasChanges(!isEqual(initialSellingPlanGroup.sellingPlans[0].pricingPolicies, sellingPlan.pricingPolicies));
  }, [initialSellingPlanGroup.sellingPlans, sellingPlan.pricingPolicies]);

  const onSave = useCallback(async () => {
    await onUpdateAddonConfig(sellingPlan, productIds, variantIds);
  }, [onUpdateAddonConfig, sellingPlan, productIds, variantIds]);

  const onDiscard = useCallback(() => {
    setSellingPlan(cloneDeep(initialSellingPlanGroup.sellingPlans[0]));
  }, [initialSellingPlanGroup]);

  const onEditProductsAndVariantsClick = useCallback(async () => {
    setShowProductOptionsModal(true);
    setModalSelectedProductOrVariantIds([...variantIds, ...productIds]);
  }, [productIds, variantIds]);

  const onEditProductsOrVariantsCb = useCallback(async () => {
    setProductIds(modalSelectedProductOrVariantIds.filter(p => p.includes("Product/")));
    setVariantIds(modalSelectedProductOrVariantIds.filter(p => p.includes("ProductVariant")));

    setShowProductOptionsModal(false);
  }, [modalSelectedProductOrVariantIds]);

  const onUpdateSellingPlan = (sellingPlan: ISellingPlanFromZodWithId, properties: { [key: string]: any }) => {
    let newSellingPlan = cloneDeep(sellingPlan);
    for (const [path, newValue] of Object.entries(properties)) {
      newSellingPlan = set(newSellingPlan, path, newValue);
    }

    setSellingPlan(newSellingPlan);
  };

  const handleRemoveProductsAndVariants = async (vntIds: string[], productId?: string) => {
    setModalSelectedProductOrVariantIds([
      ...productIds.filter(id => id !== productId),
      ...variantIds.filter(id => !vntIds.includes(id)),
    ]);

    setProductIds(productIds.filter(id => id !== productId));
    setVariantIds(variantIds.filter(id => !vntIds.includes(id)));
  };

  const linkedProducts = filterNil(
    productIds.map(productId => purchasables.find(purch => purch.shopifyId === productId))
  );

  const linkedVariantsToPurchasable: ILinkedVariantWithPurchaseableName[] = filterNil(
    flatten(
      purchasables.reduce<any[]>((arr, purch) => {
        if (purch.shopifyId && !productIds.includes(purch.shopifyId) && purch.vnts?.length) {
          arr.push(
            purch.vnts?.map(vnt => ({
              ...vnt,
              purchasableName: purch.purchasableName,
            }))
          );
        }
        return arr;
      }, [])
    ).filter(purch => purch?.shopifyId && variantIds.includes(purch.shopifyId))
  );

  const purchasableOptions = useMemo(() => {
    const filteredAndSortedPurchasables = purchasables.filter(p => !!p.shopifyId && !p.isDraftOrArchived).sort();

    if (filterProductsText) {
      return filteredAndSortedPurchasables.filter(p =>
        p.purchasableName.toLocaleLowerCase().includes(filterProductsText.toLocaleLowerCase())
      );
    }

    return filteredAndSortedPurchasables;
  }, [purchasables, filterProductsText]);

  const totalPages = Math.ceil(purchasableOptions.length / MAX_PAGE_SIZE);

  const allProductsAndVariantIds = useMemo(() => {
    const allProductsAndVariants: string[] = [];
    const allVariantsFromProducts = flatten(purchasables.map(p => p.vnts || [])).filter(
      el => el.isActiveInShopify && !el.isDraftOrArchived
    );
    for (const prod of purchasables) {
      if (prod.isActiveInShopify && !prod.isDraftOrArchived) {
        allProductsAndVariants.push(prod.shopifyId!);
      }
    }
    for (const variant of allVariantsFromProducts) {
      allProductsAndVariants.push(variant.shopifyId!);
    }
    return allProductsAndVariants;
  }, [purchasables]);

  const selectAllProducts = useCallback(() => {
    if (modalSelectedProductOrVariantIds.length === allProductsAndVariantIds.length) {
      setModalSelectedProductOrVariantIds([]);
    } else {
      setModalSelectedProductOrVariantIds(allProductsAndVariantIds);
    }
  }, [allProductsAndVariantIds, modalSelectedProductOrVariantIds]);

  return (
    <React.Fragment>
      {!!hasChanges && (
        <ContextualSaveBar
          message="Unsaved changes"
          discardAction={{
            content: "Discard changes",
            onAction: onDiscard,
          }}
          saveAction={{
            content: "Save changes",
            onAction: onSave,
          }}
        />
      )}

      <LegacyStack vertical spacing="loose">
        <React.Fragment>
          <LegacyCard sectioned>
            <LegacyStack vertical spacing="baseTight">
              <Text variant="headingMd" as="h2">
                Add-on discount
              </Text>
              <Text variant="bodyMd" as="span" color="subdued">
                Add-ons are accessible by the customer via the Account Portal. Customers can add products to their
                subscription orders as one-time add ons at a discounted price.
              </Text>
              <PricingPolicies
                sellingPlan={sellingPlan}
                fixedPolicyOnly={true}
                onPoliciesUpdate={pricingPolicies => onUpdateSellingPlan(sellingPlan, { pricingPolicies })}
              />
            </LegacyStack>
          </LegacyCard>
          <LegacyCard>
            <LegacyCard.Section>
              <LegacyStack alignment="center" distribution="equalSpacing">
                <Text variant="headingMd" as="h2">
                  Add-on products
                </Text>
                <Button onClick={onEditProductsAndVariantsClick}>Browse</Button>
              </LegacyStack>
            </LegacyCard.Section>
            <LegacyCard.Section>
              <Scrollable
                vertical
                hint
                shadow
                style={{
                  minHeight: "350px",
                  maxHeight: "350px",
                  position: "relative",
                }}
              >
                {areProductsLoading ? (
                  <LegacyStack distribution="center" alignment="center">
                    <Spinner />
                  </LegacyStack>
                ) : (
                  <React.Fragment>
                    {!linkedProducts.length && !linkedVariantsToPurchasable.length ? (
                      <NoProductsContainer>
                        <LegacyStack vertical spacing="baseTight" alignment="center" distribution="center">
                          <AddProductMajorSVG />
                          <Text variant="headingLg" as="p">
                            There are no products as add-ons yet
                          </Text>
                          <Text variant="bodyMd" as="p" color="subdued">
                            Browse to add products and variants
                          </Text>
                        </LegacyStack>
                      </NoProductsContainer>
                    ) : (
                      <React.Fragment>
                        <ResourceList
                          items={linkedProducts}
                          renderItem={purchasable => (
                            <SellingPlanGroupProductsListItem
                              purchasable={purchasable}
                              variantIds={variantIds}
                              variantToPurchasableMap={variantToPurchasableMap}
                              handleRemoveProductsAndVariants={handleRemoveProductsAndVariants}
                            />
                          )}
                        />
                        <ResourceList
                          items={linkedVariantsToPurchasable}
                          renderItem={(variant, _, index) => (
                            <SellingPlanGroupVariantToPurchaseableListItem
                              index={index}
                              variant={variant}
                              variantToPurchasableMap={variantToPurchasableMap}
                              linkedProducts={linkedProducts}
                              handleRemoveProductsAndVariants={handleRemoveProductsAndVariants}
                            />
                          )}
                        />
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </Scrollable>
            </LegacyCard.Section>
          </LegacyCard>
        </React.Fragment>

        <BrowseProductsModal
          purchasableOptions={purchasableOptions.slice(
            pageNumber * MAX_PAGE_SIZE,
            pageNumber * MAX_PAGE_SIZE + MAX_PAGE_SIZE
          )}
          selectedProductsAndVariants={modalSelectedProductOrVariantIds}
          searchText={filterProductsText}
          title={
            <LegacyStack vertical spacing="extraTight">
              <Text variant="headingLg" as="p">
                Browse products
              </Text>
              <p style={{ fontSize: "14px", lineHeight: "20px" }}>
                Select one or multiple products/variants to be included in as part of product add-ons
              </p>
            </LegacyStack>
          }
          open={showProductOptionsModal}
          onClose={() => {
            setShowProductOptionsModal(false);
            setFilterProductsText("");
          }}
          primaryAction={{
            content: "Confirm",
            onAction: onEditProductsOrVariantsCb,
          }}
          secondaryActions={[
            {
              content: "Cancel",
              onAction() {
                setShowProductOptionsModal(false);
                setFilterProductsText("");
              },
            },
          ]}
          footer={
            <PaginationContainer>
              <Pagination
                hasNext={totalPages > pageNumber + 1}
                hasPrevious={pageNumber != 0}
                label={`Showing ${pageNumber + 1} of ${totalPages || 1}`}
                onNext={() => setPageNumber(prev => prev + 1)}
                onPrevious={() => setPageNumber(prev => prev - 1)}
              />
            </PaginationContainer>
          }
          searchSuffix={
            !filterProductsText && (
              <Button onClick={selectAllProducts} plain>
                {modalSelectedProductOrVariantIds.length === allProductsAndVariantIds.length
                  ? "deselect all"
                  : "or select all"}
              </Button>
            )
          }
          onSearchChange={text => {
            setFilterProductsText(text);
            //reset page on filter change to avoid blank card
            setPageNumber(0);
          }}
          setSelectedProductAndVariantIds={setModalSelectedProductOrVariantIds}
        />
      </LegacyStack>
    </React.Fragment>
  );
}
