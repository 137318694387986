import { LegacyCard, LegacyStack, Select, Text } from "@shopify/polaris";
import { OutOfStockBehavior } from "@smartrr/shared/entities/Organization";
import React, { useCallback, useState } from "react";

import { useToast } from "../../../_sharedComponents/Toast/ToastProvider";
import { updateOrgOoSBehavior } from "../../../_state/actionCreators/organization";
import { useActiveOrganizationSubSelector } from "../../../_state/reducers/organizations";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "../../../_state/typedVendorReduxHooks";

const behaviorOptions = [
  {
    label: "Always create order and reduce inventory",
    value: OutOfStockBehavior.CREATE_ALWAYS,
    helpText:
      "Will always create the subscription order, even if there is no available product inventory set in Shopify. For example, if the inventory is set to 0 and a customer orders 10 pieces of inventory, the inventory would be reflected as -10 in Shopify.",
  },
  //Disabled until we figure out the best way to handle skipping.
  /*{
    label: "Create order only if inventory is available / SKIP",
    value: OutOfStockBehavior.CREATE_ONLY_AVAILABLE_SKIP,
    helpText:
      "Only creates the subscription order if there is available inventory in Shopify. If the inventory is not available, the order is skiped.",
  },*/
  {
    label: "Create order only if inventory is available / PAUSE",
    value: OutOfStockBehavior.CREATE_ONLY_AVAILABLE_PAUSE,
    helpText:
      "Only creates the subscription order if there is available inventory in Shopify. If the inventory is not available, the subscription is marked as PAUSED.",
  },
];

export function GlobalSettingsContainer(): JSX.Element {
  const { addToast } = useToast();
  const dispatch = useSmartrrVendorDispatch();
  const orgOutOfStockBehavior = useActiveOrganizationSubSelector(org => org?.outOfStockBehavior);
  const [selected, setSelected] = useState<OutOfStockBehavior>(
    orgOutOfStockBehavior || OutOfStockBehavior.CREATE_ALWAYS
  );
  const [helpText, setHelpText] = useState<string>(
    behaviorOptions.find(option => option.value === selected)?.helpText || ""
  );

  const activeOrganizationId = useSmartrrVendorSelector(state => state.vendorOrganizations.activeOrganizationId);
  const onChangeOutOfStockBehaviorConfig = useCallback(
    async (outOfStockBehavior: OutOfStockBehavior) => {
      if (!activeOrganizationId) {
        throw "No active organization.";
      }
      await dispatch(updateOrgOoSBehavior(outOfStockBehavior));
      addToast("Out of stock behavior updated");
      setHelpText(behaviorOptions.find(option => option.value === outOfStockBehavior)?.helpText || "");
      setSelected(outOfStockBehavior);
    },
    [activeOrganizationId, addToast, dispatch]
  );

  return (
    <LegacyCard sectioned>
      <LegacyStack vertical spacing="loose">
        <LegacyStack spacing="tight">
          <Text variant="headingMd" as="h2">
            Inventory
          </Text>
          <Text variant="bodyMd" as="span" color="subdued">
            Define the actions Smartrr should take based on available inventory levels in a customer&apos;s
            subscription.
          </Text>
        </LegacyStack>
        <Select
          value={selected}
          label="Inventory setting"
          options={behaviorOptions}
          onChange={onChangeOutOfStockBehaviorConfig}
          helpText={helpText}
        />
        <Text variant="bodyMd" as="span" color="subdued">
          Ensure your subscription products&apos; inventory settings in Shopify are enabled to &quot;Track
          quantity&quot;{" "}
          <Text variant="bodyMd" as="span" fontWeight="semibold">
            &quot;checked&quot;{" "}
          </Text>
          and &quot;Continue selling when out of stock&quot;{" "}
          <Text variant="bodyMd" as="span" fontWeight="semibold">
            &quot;unchecked&quot;
          </Text>
          .
        </Text>
      </LegacyStack>
    </LegacyCard>
  );
}
