import { Stack as MUIStack } from "@mui/material";
import { Button, LegacyCard, LegacyStack, Spinner, Text } from "@shopify/polaris";
import { Box } from "@smartrr/shared/components/primitives";
import { FeatureEnum } from "@smartrr/shared/entities/AccountPlan";
import { ISellingPlanFromZod } from "@smartrr/shared/entities/SellingPlanGroup";
import { getShopifyThemes } from "@smartrr/shared/shopifyRest/theme";
import React, { useCallback, useEffect, useState } from "react";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { TrendingListConfigAccess } from "@vendor-app/app/_state/zustand/TrendingListStore";
import { useRestClient } from "@vendor-app/app/AdminRoute/components/auth/RestProviderWrapper";

import { TrendingListsData } from "./TrendingLists";
import { TrendingListUpgrade } from "./TrendingListUpgrade";
import TrendingImage from "./TrendingNotAvailable.png";
import { isFeatureAvailable } from "../../../components/authorization/featureAccess";
import { CardTitle, NotAvaialableContainer, NotAvaialableTextContainer } from "../../libs";

const TrendingListCreate = () => {
  const actions = TrendingListConfigAccess.useActions();

  return (
    <LegacyCard sectioned>
      <Box
        vertical
        gap={1}
        py={6}
        px={1}
        alignItems="center"
        justifyContent="center"
        style={{ textAlign: "center" }}
      >
        <Text variant="headingLg" as="p">
          You don&#39;t have any trending list add-ons yet.
        </Text>
        <Text variant="bodyMd" as="span" color="subdued">
          Want to add one?
        </Text>

        <Button primary onClick={actions.createTrendingList}>
          Create trending list add-ons
        </Button>
      </Box>
    </LegacyCard>
  );
};

export const TrendingListsContainer = (): JSX.Element => {
  const { addToast } = useToast();
  const restClient = useRestClient();

  const actions = TrendingListConfigAccess.useActions();
  const config = TrendingListConfigAccess.useConfig();
  const isLoading = TrendingListConfigAccess.useLoading();
  const notYetCreated = TrendingListConfigAccess.useNotCreated();

  const user = useSmartrrVendorSelector(state => state.auth.user);
  const activePlan = useSmartrrVendorSelector(state => state.accountPlans.activePlan);

  const [mainThemeId, setMainThemeId] = useState<number>();

  const fetchTrendingLists = async () => {
    const trendingListRes = await actions.fetchTrendingListConfig();

    if (trendingListRes.result === "failure" && !trendingListRes.message?.includes("Not Found.")) {
      addToast("Error fetching trending lists", true);
    }
  };

  const getMainThemeId = useCallback(async () => {
    const res = await getShopifyThemes(restClient);
    if (res.type === "error" || !res.body.themes?.find(t => t.role === "main")) {
      addToast("Error getting main theme id");
      return;
    }
    const mainTheme = res.body.themes.find(t => t.role === "main")!;
    setMainThemeId(mainTheme.id);
  }, []);

  const onUpdateTrendingList = async (
    sellingPlan: ISellingPlanFromZod,
    productIds: string[],
    variantIds: string[]
  ) => {
    const updatedGroupRes = await actions.updateTrendingList({
      ...config,
      productIds,
      productVariantIds: variantIds,
      sellingPlans: [sellingPlan],
    });

    if (updatedGroupRes.result === "failure") {
      addToast("Failed to update trending list. Please try again later or contact support.", true);
      return;
    }

    addToast("Trending list updated");
  };

  useEffect(() => {
    void fetchTrendingLists();
    void getMainThemeId();
  }, []);

  if (!isFeatureAvailable(FeatureEnum.TRENDING, user, activePlan)) {
    return <TrendingListUpgrade />;
  }

  if (isLoading) {
    return (
      <LegacyStack vertical alignment="center">
        <Spinner />
      </LegacyStack>
    );
  }

  return (
    <React.Fragment>
      <Text variant="headingLg" as="p" fontWeight="semibold">
        Trending lists
      </Text>
      {notYetCreated ? <TrendingListCreate /> : null}
      {config ? (
        <React.Fragment>
          <div style={{ marginTop: "1.25rem" }}>
            <LegacyCard>
              <NotAvaialableContainer>
                <img src={TrendingImage} width="250" height="226" alt="Not available" />
                <NotAvaialableTextContainer>
                  <Text variant="headingMd" as="h2">
                    <MUIStack direction="column">
                      <CardTitle>
                        <Text variant="headingXs" as="h3">
                          Customer account portal
                        </Text>
                        <span>Trending lists</span>
                      </CardTitle>
                    </MUIStack>
                  </Text>
                  <p>
                    Curate up a list and use to showcase influencer picks, new arrivals, and limited edition drops
                    in your shop&apos;s Customer Account Portal. Add products below enable section visibility
                    under Theming &gt; Portal Settings &gt; Trending.
                  </p>
                </NotAvaialableTextContainer>
              </NotAvaialableContainer>
            </LegacyCard>
          </div>
          <div style={{ marginTop: "1.25rem" }}>
            <TrendingListsData
              sellingPlanGroup={config}
              mainThemeId={mainThemeId!}
              onUpdateTrendingList={onUpdateTrendingList}
            />
          </div>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};
