import { ISellingPlanGroupUpdate, SellingPlanGroupApiResponse } from "@smartrr/shared/entities/SellingPlanGroup";
import { WithResult } from "@smartrr/shared/types";
import { captureException } from "@smartrr/shared/utils/captureException";
import { useMemo } from "react";
import { create } from "zustand";

import { fullShopifyIdsToNumericIds, numericIdsToFullShopifyIds } from "@vendor-app/utils/numericIdsMapper";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

interface ITrendingListStore {
  config: SellingPlanGroupApiResponse | null | undefined;
  input: SellingPlanGroupApiResponse | null | undefined;

  isLoading: boolean;

  actions: {
    fetchTrendingListConfig: () => Promise<WithResult<SellingPlanGroupApiResponse>>;
    createTrendingList: () => Promise<WithResult<SellingPlanGroupApiResponse>>;
    updateTrendingList: (
      sellingPlanGroup: ISellingPlanGroupUpdate
    ) => Promise<WithResult<SellingPlanGroupApiResponse>>;
  };
  internal: {
    initializeConfig: (newConfig: SellingPlanGroupApiResponse | null | undefined) => void;
    whileLoading<Type>(fn: () => Promise<Type>): Promise<Type>;
  };
}

const useTrendingListConfig = create<ITrendingListStore>()((set, get) => ({
  config: null,
  input: null,

  isLoading: false,

  actions: {
    async fetchTrendingListConfig() {
      return get().internal.whileLoading(async () => {
        try {
          const data = await typedFrontendVendorApi.getReq("/selling-plan-groups/trending-list");

          if (data.type === "success") {
            const productIds = numericIdsToFullShopifyIds(data.body.productIds, "Product");
            const productVariantIds = numericIdsToFullShopifyIds(data.body.productVariantIds, "ProductVariant");

            get().internal.initializeConfig({
              ...data.body,
              productIds,
              productVariantIds,
            });

            return {
              result: "success",
              data: {
                ...data.body,
                productIds,
                productVariantIds,
              },
            };
          }

          get().internal.initializeConfig(undefined);
          return {
            result: "failure",
            message: data.message,
          };
        } catch (error) {
          captureException("Failed to fetch addons config", error);

          return {
            result: "failure",
            message: "Failed to fetch addons config",
          };
        }
      });
    },

    async createTrendingList() {
      if (get().config !== undefined) {
        return {
          result: "failure",
          message: "Please get the config first",
        };
      }
      return get().internal.whileLoading(async () => {
        try {
          const createdSellingPlanGroupRes = await typedFrontendVendorApi.postReq(
            "/selling-plan-groups/trending-list"
          );

          if (createdSellingPlanGroupRes.type === "success") {
            const productIds = numericIdsToFullShopifyIds(createdSellingPlanGroupRes.body.productIds, "Product");
            const productVariantIds = numericIdsToFullShopifyIds(
              createdSellingPlanGroupRes.body.productVariantIds,
              "ProductVariant"
            );

            get().internal.initializeConfig({
              ...createdSellingPlanGroupRes.body,
              productIds,
              productVariantIds,
            });

            return {
              result: "success",
              data: {
                ...createdSellingPlanGroupRes.body,
                productIds,
                productVariantIds,
              },
            };
          }

          return {
            result: "failure",
            message: createdSellingPlanGroupRes.message,
          };
        } catch (error) {
          captureException("Failed to create selling plan group", error);

          return {
            result: "failure",
            message: "Failed to create selling plan group",
          };
        }
      });
    },

    async updateTrendingList(sellingPlanGroup: ISellingPlanGroupUpdate) {
      return get().internal.whileLoading(async () => {
        try {
          const updatedSellingPlanGroupRes = await typedFrontendVendorApi.putReq(
            "/selling-plan-groups/trending-list",
            {
              reqBody: {
                ...sellingPlanGroup,
                productIds: fullShopifyIdsToNumericIds(sellingPlanGroup.productIds ?? []),
                productVariantIds: fullShopifyIdsToNumericIds(sellingPlanGroup.productVariantIds ?? []),
              },
            }
          );

          if (updatedSellingPlanGroupRes.type === "success") {
            const productIds = numericIdsToFullShopifyIds(updatedSellingPlanGroupRes.body.productIds, "Product");
            const productVariantIds = numericIdsToFullShopifyIds(
              updatedSellingPlanGroupRes.body.productVariantIds,
              "ProductVariant"
            );

            get().internal.initializeConfig({
              ...updatedSellingPlanGroupRes.body,
              productIds,
              productVariantIds,
            });

            return {
              result: "success",
              data: {
                ...updatedSellingPlanGroupRes.body,
                productIds,
                productVariantIds,
              },
            };
          }

          return {
            result: "failure",
            message: updatedSellingPlanGroupRes.message,
          };
        } catch (error) {
          captureException("Failed to update selling plan group", error);

          return {
            result: "failure",
            message: "Failed to update selling plan group",
          };
        }
      });
    },
  },

  internal: {
    initializeConfig(newConfig: SellingPlanGroupApiResponse | null | undefined) {
      set({
        config: newConfig,
        input: newConfig,
      });
    },

    async whileLoading<Type>(fn: () => Promise<Type>): Promise<Type> {
      set({
        isLoading: true,
      });

      const result = await fn();

      set({
        isLoading: false,
      });

      return result;
    },
  },
}));

export const TrendingListConfigAccess = {
  useLoading: () => useTrendingListConfig(state => state.isLoading),
  useConfig: () => useTrendingListConfig(state => state.config),
  useActions: () => useTrendingListConfig(state => state.actions),
  useNotCreated() {
    const config = useTrendingListConfig(state => state.config);
    return useMemo(() => config === undefined, [config]);
  },

  testing: {
    initialState: useTrendingListConfig.getState(),
    reset() {
      useTrendingListConfig.setState(this.initialState);
    },
    state: useTrendingListConfig.getState,
    actions: useTrendingListConfig.getState().actions,
  },
};
