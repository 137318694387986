import { Stack as MUIStack } from "@mui/material";
import { Button, Card, Icon, Text } from "@shopify/polaris";
import { LockMajor } from "@shopify/polaris-icons";
import React from "react";

import TrendingImage from "./TrendingNotAvailable.png";
import { NotAvaialableContainer, NotAvaialableTextContainer } from "../../libs";

export const TrendingListUpgrade = () => {
  return (
    <React.Fragment>
      <Text variant="headingLg" as="p" fontWeight="semibold">
        Trending lists
      </Text>
      <div style={{ marginTop: "1.25rem" }}>
        <Card>
          <NotAvaialableContainer>
            <img src={TrendingImage} width="250" height="226" alt="Not available" />
            <NotAvaialableTextContainer>
              <Text variant="headingMd" as="h2">
                <MUIStack direction="row" sx={{ svg: { fill: "var(--p-icon)" } }}>
                  <Icon source={LockMajor} />
                  <span style={{ marginLeft: "10px" }}>Trending lists unavailable on current plan</span>
                </MUIStack>
              </Text>
              <p>
                Upgrade to Excel to curate a list and use to showcase influencer picks, new arrivals, and limited
                edition drops in your shop&apos;s Account Portal.
              </p>
              <div>
                <Button primary url="/admin/smartrr-account">
                  💎 Upgrade to Excel
                </Button>
              </div>
            </NotAvaialableTextContainer>
          </NotAvaialableContainer>
        </Card>
      </div>
    </React.Fragment>
  );
};
