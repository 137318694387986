import { Icon, LegacyStack, Tabs, Page } from "@shopify/polaris";
import { ExternalSmallMinor, InfoMinor } from "@shopify/polaris-icons";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { useLoadingToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { SellingPlanGroupStoreAccess } from "@vendor-app/app/_state/zustand/SellingPlansStore";

import { AddOnGroupContainerData } from "./components/AddOnGroupContainer";
import { GlobalSettingsContainer } from "./components/GlobalSettingsContainer";
import { SellingPlanGroupContainer } from "./components/SellingPlanGroupContainer";
import { TrendingListsContainer } from "./components/TrendingLists";
import { Spinner } from "../components/elements/Spinner";
import { LearnMoreAboutLink, LearnMoreIconWrapper, LearnMoreLinkContainer } from "../components/elements/styles";

enum SellingPlansGroup {
  PROGRAMS,
  ADDONS,
  GLOBAL_SETTINGS,
  TRENDING_LISTS,
}

const SellingPlansContainer = styled.div`
  & > * {
    width: 100%;
  }

  .docs-link {
    bottom: 0;
    left: 50%;
    position: absolute;
    transform: translate(-50%);
  }
`;

const sellingPlanTabs = [
  {
    id: "selling-groups",
    content: "Subscription Programs",
    accessibilityLabel: "Product subscription programs",
    panelID: "subscription-programs",
  },
  {
    id: "add-ons",
    content: "Portal Add-ons",
    accessibilityLabel: "Add-on subscription programs",
    panelID: "portal-add-ons",
  },
  {
    id: "global-settings",
    content: "Global Settings",
    accessibilityLabel: "Global settings subscription programs",
    panelID: "organization-global-settings",
  },
  {
    id: "trending-lists",
    content: "Trending Lists",
    accessibilityLabel: "Trending lists subscription programs",
    panelID: "trending-lists",
  },
];

export function AdminSellingPlanGroupsRoute(): JSX.Element {
  const { addLoadingToast } = useLoadingToast();

  const sellingPlanGroups = SellingPlanGroupStoreAccess.useGroups();
  const areGroupsLoading = SellingPlanGroupStoreAccess.useAreGroupsLoading();
  const groupActions = SellingPlanGroupStoreAccess.useActions();

  const [selected, setSelected] = useState(0);
  const handleTabChange = useCallback(selectedTabIndex => setSelected(selectedTabIndex), []);

  useEffect(() => {
    groupActions.fetchSellingPlanGroups(false);
  }, []);

  useEffect(() => {
    addLoadingToast(areGroupsLoading);
  }, [areGroupsLoading]);

  if (areGroupsLoading) {
    return <Spinner accessibilityLabel="Spinner" />;
  }

  const renderSwitch = (selectedTab: SellingPlansGroup) => {
    switch (selectedTab) {
      case SellingPlansGroup.PROGRAMS: {
        return <SellingPlanGroupContainer sellingPlanGroups={sellingPlanGroups} />;
      }
      case SellingPlansGroup.ADDONS: {
        return <AddOnGroupContainerData />;
      }
      case SellingPlansGroup.GLOBAL_SETTINGS: {
        return <GlobalSettingsContainer />;
      }
      case SellingPlansGroup.TRENDING_LISTS: {
        return <TrendingListsContainer />;
      }
      default: {
        return null;
      }
    }
  };

  return (
    <React.Fragment>
      <Page narrowWidth>
        <SellingPlansContainer>
          <Tabs tabs={sellingPlanTabs} selected={selected} onSelect={handleTabChange} fitted>
            <div style={{ paddingTop: "30px" }}>{renderSwitch(selected)}</div>
          </Tabs>
          <div className="docs-link">
            <LegacyStack distribution="center" spacing="tight">
              <Icon source={InfoMinor} color="highlight" />
              <LearnMoreLinkContainer>
                Learn more about&nbsp;
                <LearnMoreAboutLink
                  href="https://help.smartrr.com/docs/support/subscription-setup/how-do-i-set-up-subscription-programs"
                  rel="noreferrer"
                  target="_blank"
                >
                  Subscription Programs.
                  <LearnMoreIconWrapper>
                    <Icon source={ExternalSmallMinor} color="base" />
                  </LearnMoreIconWrapper>
                </LearnMoreAboutLink>
              </LearnMoreLinkContainer>
            </LegacyStack>
          </div>
        </SellingPlansContainer>
      </Page>
    </React.Fragment>
  );
}
