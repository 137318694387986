import { Button, LegacyCard, LegacyStack, Text } from "@shopify/polaris";
import { Box } from "@smartrr/shared/components/primitives";
import { adminConfigRoutePrefix } from "@smartrr/shared/constants";
import { ISmartrrSellingPlanGroup } from "@smartrr/shared/entities/SellingPlanGroup";
import { shopifyGidToNumber } from "@smartrr/shared/utils/ensureShopifyGid";
import { pluralize } from "@smartrr/shared/utils/pluralize";
import { ProductsImages } from "@vendor-app/app/_sharedComponents/SubscriptionProgramCard/components/ProductsImages";
import { navigateWithShopInQuery } from "@vendor-app/utils/navigateWithShopInQuery";
import React from "react";
import styled from "styled-components";
import { getAllGroupProductsIdsFromVariantMap } from "../utils";

const CountsWrapper = styled.div`
  margin-top: 15px;
  .Polaris-LegacyStack {
    .Polaris-LegacyStack__Item {
      .Polaris-LegacyCard {
        background: #1a1c1d;
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        padding: 9px 11px;
      }
    }
  }
`;

const CardWrapper = styled.div`
  cursor: pointer;
`;

export function SellingPlanGroupContainer({
  sellingPlanGroups,
}: {
  sellingPlanGroups: ISmartrrSellingPlanGroup[];
}): JSX.Element {
  return (
    <React.Fragment>
      {sellingPlanGroups.length === 0 ? (
        <LegacyCard sectioned>
          <Box
            vertical
            gap={1}
            py={6}
            px={1}
            alignItems="center"
            justifyContent="center"
            style={{ textAlign: "center" }}
          >
            <Text variant="headingLg" as="p">
              You don&#39;t have any subscription programs yet.
            </Text>
            <Text variant="bodyMd" as="span" color="subdued">
              Let&#39;s make one!
            </Text>
            <Button
              id="smartrr-selling-plan-group__create-program__button"
              primary
              onClick={() => navigateWithShopInQuery(`${adminConfigRoutePrefix}/plans/create`)}
            >
              Create program
            </Button>
          </Box>
        </LegacyCard>
      ) : (
        <LegacyStack vertical spacing="loose">
          <Text variant="headingMd" as="h2">
            <LegacyStack distribution="equalSpacing">
              <Text
                id="smartrr-selling-plan-group__subscription-programs-header"
                variant="headingLg"
                as="p"
                fontWeight="semibold"
              >
                Subscription Programs
              </Text>
              <Button
                id="smartrr-selling-plan-group__create-program__button"
                primary
                onClick={() => navigateWithShopInQuery(`${adminConfigRoutePrefix}/plans/create`)}
              >
                Create program
              </Button>
            </LegacyStack>
          </Text>
          {sellingPlanGroups.map(group => {
            return (
              <CardWrapper
                id={`smartrr-selling-plan-group__group-id__${group.shopifyNumericId}`}
                data-testid={`smartrr-selling-plan-group__group-id`}
                key={group.shopifyNumericId}
                onClick={() =>
                  navigateWithShopInQuery(
                    `${adminConfigRoutePrefix}/plans/${shopifyGidToNumber(group.shopifyNumericId)}`
                  )
                }
              >
                <LegacyCard key={group.shopifyId} sectioned>
                  <LegacyStack vertical spacing="tight" distribution="equalSpacing">
                    <Text variant="headingMd" as="h2">
                      <LegacyStack distribution="equalSpacing">
                        <Text variant="bodyLg" as="p" fontWeight="semibold">
                          {group.name}
                        </Text>
                      </LegacyStack>
                    </Text>
                    <LegacyStack vertical spacing="extraTight">
                      <span>{group.sellingPlans.map(plan => plan.options[0]).join(", ")}</span>
                      {!!group.merchantCode && (
                        <Text variant="bodyMd" as="span" color="subdued">
                          {group.merchantCode}
                        </Text>
                      )}
                    </LegacyStack>
                  </LegacyStack>
                  <LegacyStack distribution="equalSpacing">
                    <ProductsImages
                      productIds={getAllGroupProductsIdsFromVariantMap(group.enabledProductIdToVariantIdMap)}
                    />
                    <CountsWrapper>
                      <LegacyStack spacing="tight">
                        <LegacyCard>{pluralize(group.subscriptionCount, "subscriber")}</LegacyCard>
                        <LegacyCard>{pluralize(group.sellingPlanCount, "plan")}</LegacyCard>
                      </LegacyStack>
                    </CountsWrapper>
                  </LegacyStack>
                </LegacyCard>
              </CardWrapper>
            );
          })}
        </LegacyStack>
      )}
    </React.Fragment>
  );
}
