import { useApolloClient } from "@apollo/client";
import {
  Button,
  Collapsible,
  ContextualSaveBar,
  Icon,
  LegacyCard,
  LegacyStack,
  Pagination,
  ResourceItem,
  ResourceList,
  Scrollable,
  Text,
  Thumbnail,
} from "@shopify/polaris";

import { BrowseProductsModal } from "@vendor-app/app/_sharedComponents/BrowseProductsModal";
import { Box } from "@smartrr/shared/components/primitives";
import { CancelSmallMinor, ChevronDownMinor, ChevronUpMinor } from "@shopify/polaris-icons";
import { SELLING_PLAN_GROUP_ADDON_TAG, SELLING_PLAN_GROUP_HIDDEN_TAG } from "@smartrr/shared/constants";
import { ISmartrrAddOnsConfig } from "@smartrr/shared/entities/SellingPlanGroup";
import {
  SellingPlanCategory,
  SellingPlanGroupInput,
  SellingPlanInput,
  SellingPlanInterval,
  SellingPlanPricingPolicyAdjustmentType,
  SellingPlanPricingPolicyInput,
} from "@smartrr/shared/shopifyGraphQL/api";
import {
  mutationShopifyCreateSellingPlanGroup,
  mutationShopifyUpdateSellingPlanGroup,
  querySellingPlanGroupProducts,
  querySellingPlanGroupVariants,
  queryShopifySellingPlanGroup,
} from "@smartrr/shared/shopifyGraphQL/sellingPlans";
import { delay } from "@smartrr/shared/utils/delay";
import { ensureShopifyGid } from "@smartrr/shared/utils/ensureShopifyGid";
import { filterNil } from "@smartrr/shared/utils/filterUndefined";
import { MAX_PAGE_SIZE } from "@smartrr/shared/utils/paginatedQuery";
import {
  getVariantImageFromPurchasableMap,
  useVariantToPurchasableMap,
} from "@smartrr/shared/utils/useVariantToPurchasableMap";
import config from "@vendor-app/config";
import { cloneDeep, differenceWith, flatten, last, omit } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { v4 } from "uuid";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import {
  addProductsToGroup,
  addVariantsToGroup,
  bustAddOnsCache,
  removeProductsFromGroup,
  removeVariantsFromGroup,
} from "@vendor-app/utils/sellingPlanGroupProductManagement";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

import { PricingPolicies } from "./PricingPolicies";
import CursorPagination from "../../../_sharedComponents/CursorPagination";
import { AddProductMajorSVG } from "../../../_sharedComponents/SVGs/AddProductMajorSVG";
import { Spinner } from "../../components/elements/Spinner";
import { DEFAULT_PAGE_SIZE, emptySellingPlan, emptySellingPlanGroup } from "../constants";
import {
  determineSellingPlanOperations,
  formatSellingPlanGroupQueryResponse,
  sellingPlanToSellingPlanInput,
} from "../utils";
import { validatePricingPolicyWithZod } from "../../AdminSellingPlanGroupsRouteFeatureFlagged/utils";
import { isDefaultVariant } from "@smartrr/shared/utils/isDefaultVariant";
import { StatusBadge } from "@vendor-app/app/_sharedComponents/StatusBadge";

const discountPercentageDefault = 10; // 10%

const ChevronContainer = styled.div`
  display: flex;

  & > *:first-child {
    margin-right: 0.5rem;
  }
`;

const NoProductsContainer = styled.div`
  padding: 4rem 1rem;
  text-align: center;
`;

const PaginationContainer = styled.div`
  & > div {
    & > nav {
      > div {
        display: flex;
        justify-content: space-between;

        & .Polaris-ButtonGroup__Item:last-child {
          .Polaris-Button {
            border-radius: 0.25rem 0 0 0.25rem;
          }
        }

        & .Polaris-ButtonGroup__Item:last-child {
          .Polaris-Button {
            border-radius: 0 0.25rem 0.25rem 0;
          }
        }
      }
    }
  }
`;

const VariantOrProductTitle = styled.span.attrs((props: { variation: "strong" }) => ({ ...props }))`
  display: block;
  font-weight: ${props => (props.variation === "strong" ? "bold" : "normal")};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 300px;
  @media only screen and (max-width: 880px) {
    width: 265px;
  }
  @media only screen and (max-width: 830px) {
    width: 28vw;
  }
  @media only screen and (max-width: 780px) {
    width: 24vw;
  }
  @media only screen and (max-width: 768px) {
    width: 42vw;
  }
  @media only screen and (max-width: 550px) {
    width: 26vw;
  }
`;

const TitleForLinkedToProductVariants = styled.span`
  display: block;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 270px;
  @media only screen and (max-width: 880px) {
    width: 225px;
  }
  @media only screen and (max-width: 830px) {
    width: 24vw;
  }
  @media only screen and (max-width: 780px) {
    width: 20vw;
  }
  @media only screen and (max-width: 750px) {
    width: 38vw;
  }
  @media only screen and (max-width: 555px) {
    width: 22vw;
  }
`;

const ResourceItemContainer = styled.div`
  & .Polaris-ResourceItem__Container {
    padding-left: 40px;
    padding-right: 0;
  }
`;

const ProductAndVariantTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const createNewSellingPlan = () => {
  const newSP: SellingPlanInput = {
    ...emptySellingPlan,
    name: `Smartrr Add-On Subscription Program`,
    options: ["Smartrr Add-On Subscription Program"],
    deliveryPolicy: { recurring: { interval: SellingPlanInterval.Day, intervalCount: 1 } },
    billingPolicy: { recurring: { interval: SellingPlanInterval.Day, intervalCount: 1 } },
    pricingPolicies: [
      {
        fixed: {
          adjustmentType: SellingPlanPricingPolicyAdjustmentType.Percentage,
          adjustmentValue: {
            percentage: discountPercentageDefault,
          },
        },
      },
    ],
  };
  return newSP;
};

const createNewSellingPlanGroup = () => {
  const uniqueId = v4();
  return {
    ...emptySellingPlanGroup,
    name: `${SELLING_PLAN_GROUP_HIDDEN_TAG}${SELLING_PLAN_GROUP_ADDON_TAG}[${uniqueId}]`,
    options: ["Add-On"],
    merchantCode: `${SELLING_PLAN_GROUP_HIDDEN_TAG}${SELLING_PLAN_GROUP_ADDON_TAG}[${uniqueId}]`,
    description: "Smartrr Add-ons",
  };
};

export function AddOnGroupContainerData(): JSX.Element {
  const apolloClient = useApolloClient();
  const { addToast } = useToast();
  const [addOnsConfig, setAddOnsConfig] = useState<ISmartrrAddOnsConfig | null>(null);

  const addOnsSellingPlanGroupId = useMemo(() => addOnsConfig?.shopifyId || undefined, [addOnsConfig]);

  const initialSellingPlanGroup = useMemo(() => addOnsConfig || createNewSellingPlanGroup(), [addOnsConfig]);
  const areProductsLoading = useSmartrrVendorSelector(state => state.purchasables.isLoadingAdditionaly);

  const [sellingPlanGroup, setAddOnsConfigInput] = useState<SellingPlanGroupInput>(initialSellingPlanGroup);

  const initialSellingPlans = useMemo(() => (addOnsConfig ? [] : [createNewSellingPlan()]), [addOnsConfig]);
  const [sellingPlans, setSellingPlans] = useState<SellingPlanInput[]>(initialSellingPlans);

  const [isLoading, setIsLoading] = useState(true);

  const fetchAddonsConfig = async (bustCache?: boolean) => {
    setIsLoading(true);
    const addOnsConfigRes = await typedFrontendVendorApi.getReq("/add-ons-config", {
      query: {
        cache: bustCache ? "false" : "true",
      },
    });

    if (addOnsConfigRes.type === "error") {
      addToast("Error fetching trending lists");
      return;
    }

    setAddOnsConfig(addOnsConfigRes.body[0] || null);
    const id = addOnsConfigRes.body[0]?.shopifyId || null;

    try {
      if (id) {
        const res = await queryShopifySellingPlanGroup(apolloClient, id);
        if (!res.sellingPlanGroup) {
          addToast("There was an error fetching add-on configuration");
          setIsLoading(false);
          return;
        }
        const { sellingPlanGroup } = res;

        const { sellingPlanGroupInput, sellingPlanInputs } =
          formatSellingPlanGroupQueryResponse(sellingPlanGroup);
        setAddOnsConfigInput(sellingPlanGroupInput);
        setSellingPlans(sellingPlanInputs);
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchAddonsConfig();
  }, []);

  const onSave = async () => {
    await fetchAddonsConfig(true);
  };

  if (isLoading) {
    return (
      <LegacyStack vertical alignment="center">
        <Spinner />
      </LegacyStack>
    );
  }

  return (
    <AddOnGroupContainer
      sellingPlanGroupId={addOnsSellingPlanGroupId}
      sellingPlanGroup={sellingPlanGroup}
      sellingPlan={sellingPlans[0]}
      onSave={onSave}
      areProductsLoading={areProductsLoading}
    />
  );
}

export interface AddOnGroupContainerProps {
  sellingPlanGroupId?: string | null;
  sellingPlanGroup: SellingPlanGroupInput;
  sellingPlan: SellingPlanInput;
  areProductsLoading: boolean;
  onSave: () => Promise<void>;
}

export function AddOnGroupContainer({
  sellingPlanGroupId,
  sellingPlanGroup: initialSellingPlanGroup,
  sellingPlan: initialSellingPlan,
  onSave: onSaveChanges,
  areProductsLoading,
}: AddOnGroupContainerProps): JSX.Element {
  const apolloClient = useApolloClient();
  const { addToast } = useToast();
  const { purchasables } = useSmartrrVendorSelector(state => state.purchasables);
  const variantToPurchasableMap = useVariantToPurchasableMap(purchasables);

  const [hasChanges, setHasChanges] = useState(false);

  const [sellingPlanGroup, setSellingPlanGroup] = useState<SellingPlanGroupInput>({
    ...cloneDeep(initialSellingPlanGroup),
    appId: config.shopify.appId,
  });
  const [sellingPlan, setSellingPlan] = useState<SellingPlanInput>(initialSellingPlan);

  const [linkedProductIds, setLinkedProductIds] = useState<string[]>([]);
  const [linkedVariantIds, setLinkedVariantIds] = useState<string[]>([]);

  const [productIds, setProductIds] = useState(linkedProductIds);
  const [variantIds, setVariantIds] = useState<string[]>([]);

  const [pageNumber, setPageNumber] = useState<number>(0);

  // link products/variants state items
  const [showProductOptionsModal, setShowProductOptionsModal] = useState(false);
  const [modalSelectedProductOrVariantIds, setModalSelectedProductOrVariantIds] = useState<string[]>([]);
  const [isProductOrVariantLinkedLoading, setIsProductOrVariantLinkedLoading] = useState(false);

  const [filterProductsText, setFilterProductsText] = useState<string>("");

  const [hasNextProductsAndVariantsPage, setHasNextProductsAndVariantsPage] = useState<boolean>(false);
  const [allProductsAndVariantsPageInfo, setAllProductsAndVariantsPageInfo] = useState<{
    productsCursor: string | null;
    variantsCursor: string | null;
    itemsCount: number;
  }>({ productsCursor: null, variantsCursor: null, itemsCount: 0 });

  const [isSaving, setIsSaving] = useState(false);

  const [reloadProductsPagination, setReloadProductsPagination] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const [open, setOpen] = useState<boolean>(true);
  const [lastIndex, setLastIndex] = useState<number>(0);
  const [lengthOfVariantsOnPage, setLengthOfVariantsOnPage] = useState<number[]>([0]);

  useEffect(() => {
    if (reloadProductsPagination) {
      setCurrentPage(0);
      setReloadProductsPagination(false);
    }
  }, [reloadProductsPagination]);

  const onSave = useCallback(async () => {
    setIsSaving(true);

    if (sellingPlanGroupId) {
      if (sellingPlan.pricingPolicies) {
        const res = validatePricingPolicyWithZod(sellingPlan.pricingPolicies);
        if (!res.success && res.error) {
          setIsSaving(false);
          addToast(res.error, true);
          return;
        }
      }

      const groupToSave = omit(sellingPlanGroup, ["id", "sellingPlans"]);
      const { sellingPlansToCreate, sellingPlansToDelete, sellingPlansToUpdate } = determineSellingPlanOperations(
        [sellingPlan],
        [initialSellingPlan],
        SellingPlanCategory.TryBeforeYouBuy
      );

      const res = await mutationShopifyUpdateSellingPlanGroup(
        sellingPlanGroupId,
        {
          ...groupToSave,
          sellingPlansToCreate,
          sellingPlansToUpdate,
          sellingPlansToDelete: sellingPlansToDelete.map(p => p.id!),
        },
        apolloClient
      );

      if (res.type === "success") {
        if (res.body.data?.sellingPlanGroupUpdate?.userErrors.length) {
          setIsSaving(false);
          addToast(
            `Errors updating add-on configuration: \n${(
              res.body.data?.sellingPlanGroupUpdate?.userErrors || []
            ).join("\n")}`
          );
          return;
        }

        const updatedSellingPlan =
          res.body.data?.sellingPlanGroupUpdate?.sellingPlanGroup?.sellingPlans.edges[0]?.node;
        if (updatedSellingPlan) {
          const updatedSellingPlanInput = sellingPlanToSellingPlanInput(updatedSellingPlan);
          setSellingPlan(updatedSellingPlanInput);
        } else {
          console.error("Could not find add-on configuration from response, this should never happen");
        }
      } else {
        addToast(
          `Errors updating add-on configuration: ${res.body.errors?.map(error => error.message).join(", ") || ""}`
        );
        setIsSaving(false);
      }
    } else {
      const res = await mutationShopifyCreateSellingPlanGroup(
        {
          ...sellingPlanGroup,
          sellingPlansToCreate: [
            {
              ...sellingPlan,
              category: SellingPlanCategory.TryBeforeYouBuy,
            },
          ],
        },
        apolloClient
      );

      if (res.type === "success") {
        const { data } = res.body;
        if (data?.sellingPlanGroupCreate?.userErrors.length) {
          setIsSaving(false);
          addToast(
            `Errors updating add-on configuration: \n${(
              res.body.data?.sellingPlanGroupCreate?.userErrors || []
            ).join("\n")}`
          );
          return;
        }
      }
    }

    //cant fetch add-ons config if there is no delay
    if (!sellingPlanGroupId) {
      await delay(5000);
    }

    await onSaveChanges();
    setIsSaving(false);
    setHasChanges(false);
    addToast("Add-on configuration updated");
  }, [sellingPlan, sellingPlanGroup, sellingPlanGroupId]);

  const onDiscard = useCallback(() => {
    setSellingPlanGroup({ ...initialSellingPlanGroup });
    setSellingPlan(cloneDeep(initialSellingPlan));
    setHasChanges(false);
  }, [initialSellingPlanGroup, initialSellingPlan]);

  const onEditProductsAndVariantsClick = useCallback(async () => {
    if (sellingPlanGroupId) {
      setShowProductOptionsModal(true);
      setModalSelectedProductOrVariantIds([...variantIds, ...productIds]);
    } else {
      const res = await mutationShopifyCreateSellingPlanGroup(
        {
          ...sellingPlanGroup,
          sellingPlansToCreate: [
            {
              ...sellingPlan,
              category: SellingPlanCategory.TryBeforeYouBuy,
            },
          ],
        },
        apolloClient
      );

      if (res.type === "success") {
        const { data } = res.body;
        if (data?.sellingPlanGroupCreate?.userErrors.length) {
          addToast(
            `Errors updating add-on configuration: \n${(
              res.body.data?.sellingPlanGroupCreate?.userErrors || []
            ).join("\n")}`
          );
        } else {
          setShowProductOptionsModal(true);
          setModalSelectedProductOrVariantIds([...variantIds, ...productIds]);
        }
      }
    }
  }, [sellingPlanGroupId, productIds, variantIds]);

  const onEditProducts = useCallback(
    async (ids: string[]) => {
      if (!sellingPlanGroup) {
        return;
      }

      if (!sellingPlanGroupId) {
        addToast("Group does not have an id");
        return;
      }
      const toAdd = ids.filter(id => !productIds.includes(id));
      const toRemove = productIds.filter(id => !ids.includes(id));

      if (toAdd.length) {
        await addProductsToGroup(apolloClient, sellingPlanGroupId, toAdd);
        addToast("Product(s) added to program");
      }

      if (toRemove.length) {
        await removeProductsFromGroup(apolloClient, sellingPlanGroupId, toRemove);
        addToast("Product(s) removed from program");
      }

      const productIdsToSet = (ids: string[]) => ids.filter(id => !toRemove.includes(id)).concat(toAdd);
      setProductIds(productIdsToSet);
      setLinkedProductIds(productIdsToSet);

      setShowProductOptionsModal(false);
    },
    [sellingPlanGroup, purchasables, productIds]
  );

  const onEditVariants = useCallback(
    async (ids: string[]) => {
      if (!sellingPlanGroup) {
        return;
      }

      if (!sellingPlanGroupId) {
        addToast("Group does not have an id");
        return;
      }
      const toAdd = ids.filter(id => !variantIds.includes(id));
      const toRemove = variantIds.filter(id => !ids.includes(id));

      if (toAdd.length) {
        await addVariantsToGroup(apolloClient, sellingPlanGroupId, toAdd);
        addToast("Variant(s) added to program");
      }

      if (toRemove.length) {
        await removeVariantsFromGroup(apolloClient, sellingPlanGroupId, toRemove);
        addToast("Variant(s) removed from program");
      }

      const variantIdsToSet = (ids: string[]) => ids.filter(id => !toRemove.includes(id)).concat(toAdd);
      setVariantIds(variantIdsToSet);

      setShowProductOptionsModal(false);
    },
    [sellingPlanGroup, purchasables, variantIds, sellingPlan]
  );

  const onEditProductsOrVariantsCb = useCallback(async () => {
    setIsProductOrVariantLinkedLoading(true);
    setFilterProductsText("");

    await onEditProducts(modalSelectedProductOrVariantIds.filter(p => !p.includes("ProductVariant")));
    await onEditVariants(modalSelectedProductOrVariantIds.filter(p => p.includes("ProductVariant")));

    bustAddOnsCache();
    await paginatedVariantsQuery(null, true);
    setIsProductOrVariantLinkedLoading(false);

    setReloadProductsPagination(true);
  }, [modalSelectedProductOrVariantIds, onEditProducts, onEditVariants]);

  const onUpdateSellingPlan = (
    sellingPlan: SellingPlanInput,
    pricingPolicies: SellingPlanPricingPolicyInput[]
  ) => {
    setHasChanges(true);
    setSellingPlan({ ...sellingPlan, pricingPolicies });
  };

  const handleRemoveProductsAndVariants = async (vntIds: string[], productId?: string) => {
    setIsProductOrVariantLinkedLoading(true);

    setModalSelectedProductOrVariantIds([
      ...productIds.filter(id => id !== productId),
      ...variantIds.filter(id => !vntIds.includes(id)),
    ]);

    await onEditProducts(productIds.filter(id => id !== productId));
    await onEditVariants(variantIds.filter(id => !vntIds.includes(id)));

    bustAddOnsCache();
    await paginatedVariantsQuery(null, true);
    setIsProductOrVariantLinkedLoading(false);
    setReloadProductsPagination(true);
  };

  const paginatedVariantsQuery = async (newCursor: string | null, bustCache?: boolean) => {
    const paginatedVariantIds: string[] = [];
    const paginatedProductIds = new Set<string>();

    setIsProductOrVariantLinkedLoading(true);
    const res = await querySellingPlanGroupVariants(
      apolloClient,
      ensureShopifyGid("SellingPlanGroup", sellingPlanGroupId!),
      DEFAULT_PAGE_SIZE,
      newCursor,
      bustCache
    );

    if (res.type === "error" || !res.body.data.sellingPlanGroup) {
      return {
        lastCursor: null,
        hasNextPage: false,
      };
    }

    const {
      edges,
      pageInfo: { hasNextPage },
    } = res.body.data.sellingPlanGroup.productVariants;

    const nodes = edges.map(({ node }: any) => node) || [];
    for (const node of nodes) {
      paginatedVariantIds.push(node.id);
      paginatedProductIds.add(node.product.id);
    }

    setHasNextProductsAndVariantsPage(hasNextPage);
    setLinkedVariantIds(paginatedVariantIds);
    setLinkedProductIds([...paginatedProductIds]);
    setIsProductOrVariantLinkedLoading(false);

    return { lastCursor: last(edges)?.cursor, hasNextPage };
  };

  const loadProductsAndVariants = async (
    sellingPlanGroupId?: string,
    productsCursor?: string | null,
    variantsCursor?: string | null
  ) => {
    const productIds: string[] = [];
    const variantIds: string[] = [];
    const [productsRes, variantsRes] = await Promise.all([
      querySellingPlanGroupProducts(apolloClient, sellingPlanGroupId!, MAX_PAGE_SIZE, productsCursor),
      querySellingPlanGroupVariants(apolloClient, sellingPlanGroupId!, MAX_PAGE_SIZE, variantsCursor),
    ]);

    if (
      variantsRes.type === "error" ||
      !variantsRes.body.data.sellingPlanGroup ||
      productsRes.type === "error" ||
      !productsRes.body.data.sellingPlanGroup
    ) {
      return {
        productIds: [],
        variantIds: [],
      };
    }

    const variantsEdges = variantsRes.body.data.sellingPlanGroup.productVariants.edges;
    const productsEdges = productsRes.body.data.sellingPlanGroup.products.edges;

    const variantNodes = variantsEdges.map(({ node }) => node) || [];
    const productNodes = productsEdges.map(({ node }) => node) || [];

    const vCursor = variantsEdges?.[variantsEdges.length - 1]?.cursor;
    const pCursor = productsEdges?.[productsEdges.length - 1]?.cursor;

    setAllProductsAndVariantsPageInfo(prevState => ({
      variantsCursor: vCursor,
      productsCursor: pCursor,
      itemsCount: prevState.itemsCount + MAX_PAGE_SIZE,
    }));

    for (const node of variantNodes) {
      variantIds.push(node.id);
    }
    for (const node of productNodes) {
      productIds.push(node.id);
    }

    return {
      productIds,
      variantIds,
    };
  };

  useEffect(() => {
    if ((currentPage + 1) * DEFAULT_PAGE_SIZE > allProductsAndVariantsPageInfo.itemsCount && sellingPlanGroupId) {
      loadProductsAndVariants(
        sellingPlanGroupId,
        allProductsAndVariantsPageInfo.productsCursor,
        allProductsAndVariantsPageInfo.variantsCursor
      ).then(({ productIds, variantIds }) => {
        setProductIds(ids => [...ids, ...productIds]);
        setVariantIds(ids => [...ids, ...variantIds]);
      });
    }
  }, [currentPage]);

  useEffect(() => {
    if (sellingPlanGroupId) {
      paginatedVariantsQuery(null, true);
      loadProductsAndVariants(sellingPlanGroupId).then(({ productIds, variantIds }) => {
        setProductIds(productIds);
        setVariantIds(variantIds);
      });
    }
  }, []);

  const linkedProducts = filterNil(
    linkedProductIds
      .filter(productId => productIds.includes(productId))
      .map(productId => purchasables.find(purch => purch.shopifyId === productId))
  );

  const linkedVariantsToShow = filterNil(
    differenceWith(
      linkedVariantIds,
      flatten(purchasables.filter(purch => productIds.includes(purch.shopifyId!)).map(purch => purch.vnts)),
      (variantId, purch) => purch?.shopifyId === variantId
    )
  );

  const linkedVariantsToPurchasable = filterNil(
    flatten(
      purchasables.map(purch =>
        purch.vnts?.map(vnt => ({
          ...vnt,
          purchasableName: purch.purchasableName,
        }))
      )
    ).filter(purch => linkedVariantsToShow.includes(purch?.shopifyId!))
  );

  const getAllLinkedProductVariantsLengthToNumber = (toNumber: number) => {
    const previousLinkedProducts = linkedProducts.slice(0, toNumber).map(p => p.shopifyId);
    return flatten(
      purchasables.filter(purch => previousLinkedProducts.includes(purch.shopifyId)).map(purch => purch.vnts)
    ).length;
  };

  const renderIndexForSubVariant = (productIndex: number, variantIndex: number) => {
    const variantsLengthFromPreviousLinkedProducts = getAllLinkedProductVariantsLengthToNumber(productIndex);
    return (
      lastIndex + (productIndex - 1 >= 0 ? variantsLengthFromPreviousLinkedProducts || 1 : 0) + variantIndex + 1
    );
  };

  const handleSettingCurrentPage = (pageNumber: number) => {
    const currentPageLinkedProductVariantsLength = getAllLinkedProductVariantsLengthToNumber(
      linkedProducts.length
    );
    const variantsLength = currentPageLinkedProductVariantsLength + linkedVariantsToPurchasable.length;

    if (currentPage < pageNumber) {
      setLastIndex(lastIndex => lastIndex + variantsLength);
      setLengthOfVariantsOnPage(arr => [...arr, variantsLength]);
    } else {
      setLastIndex(lastIndex => lastIndex - lengthOfVariantsOnPage[lengthOfVariantsOnPage.length - 1]);
      setLengthOfVariantsOnPage(arr => arr.slice(0, -1));
    }
    if (setCurrentPage) {
      setCurrentPage(pageNumber);
    }
  };

  const purchasableOptions = useMemo(() => {
    const filteredAndSortedPurchasables = purchasables.filter(p => !!p.shopifyId && !p.isDraftOrArchived).sort();

    if (filterProductsText) {
      return filteredAndSortedPurchasables.filter(p =>
        p.purchasableName.toLocaleLowerCase().includes(filterProductsText.toLocaleLowerCase())
      );
    }

    return filteredAndSortedPurchasables;
  }, [purchasables, filterProductsText]);

  const totalPages = Math.ceil(purchasableOptions.length / MAX_PAGE_SIZE);

  const allProductsAndVariantIds = useMemo(() => {
    const allProductsAndVariants: string[] = [];
    const allVariantsFromProducts = flatten(purchasables.map(p => p.vnts || [])).filter(
      el => el.isActiveInShopify && !el.isDraftOrArchived
    );
    for (const prod of purchasables) {
      if (prod.isActiveInShopify && !prod.isDraftOrArchived) {
        allProductsAndVariants.push(prod.shopifyId!);
      }
    }
    for (const variant of allVariantsFromProducts) {
      allProductsAndVariants.push(variant.shopifyId!);
    }
    return allProductsAndVariants;
  }, [purchasables]);

  const selectAllProducts = useCallback(() => {
    if (modalSelectedProductOrVariantIds.length === allProductsAndVariantIds.length) {
      setModalSelectedProductOrVariantIds([]);
    } else {
      setModalSelectedProductOrVariantIds(allProductsAndVariantIds);
    }
  }, [allProductsAndVariantIds, modalSelectedProductOrVariantIds]);

  useEffect(() => {
    if (currentPage === 0) {
      setLastIndex(0);
      setLengthOfVariantsOnPage([0]);
    }
  }, [currentPage]);

  return (
    <LegacyStack vertical spacing="loose">
      {!!hasChanges && (
        <ContextualSaveBar
          message="Unsaved changes"
          discardAction={{
            content: "Discard changes",
            onAction: onDiscard,
          }}
          saveAction={{
            loading: isSaving,
            content: "Save changes",
            onAction: onSave,
          }}
        />
      )}
      {sellingPlanGroupId ? (
        <React.Fragment>
          <LegacyCard sectioned>
            <LegacyStack vertical spacing="baseTight">
              <Text variant="headingMd" as="h2">
                Add-on discount
              </Text>
              <Text variant="bodyMd" as="span" color="subdued">
                Add-ons are accessible by the customer via the Account Portal. Customers can add products to their
                subscription orders as one-time add ons at a discounted price.
              </Text>
              <PricingPolicies
                sellingPlan={sellingPlan}
                fixedPolicyOnly={true}
                onPoliciesUpdate={pricingPolicies => onUpdateSellingPlan(sellingPlan, pricingPolicies)}
              />
            </LegacyStack>
          </LegacyCard>
          <LegacyCard>
            <LegacyCard.Section>
              <LegacyStack alignment="center" distribution="equalSpacing">
                <Text variant="headingMd" as="h2">
                  Add-on products
                </Text>
                <Button onClick={onEditProductsAndVariantsClick}>Browse</Button>
              </LegacyStack>
            </LegacyCard.Section>
            <LegacyCard.Section>
              <Scrollable
                vertical
                hint
                shadow
                style={{
                  minHeight: "350px",
                  maxHeight: "350px",
                  position: "relative",
                }}
              >
                {isProductOrVariantLinkedLoading || areProductsLoading ? (
                  <LegacyStack distribution="center" alignment="center">
                    <Spinner />
                  </LegacyStack>
                ) : (
                  <React.Fragment>
                    {!linkedProducts.length && !linkedVariantIds.length ? (
                      <NoProductsContainer>
                        <LegacyStack vertical spacing="baseTight" alignment="center" distribution="center">
                          <AddProductMajorSVG />
                          <Text variant="headingLg" as="p">
                            There are no products as add-ons yet
                          </Text>
                          <Text variant="bodyMd" as="p" color="subdued">
                            Browse to add products and variants
                          </Text>
                        </LegacyStack>
                      </NoProductsContainer>
                    ) : (
                      <React.Fragment>
                        <ResourceList
                          items={linkedProducts}
                          renderItem={(purchasable, _, productIndex) => {
                            const variantNames = purchasable.vnts
                              ?.filter(
                                vnt =>
                                  variantIds.includes(vnt.shopifyId!) &&
                                  !isDefaultVariant(vnt.purchasableVariantName)
                              )
                              .map(vnt => vnt.purchasableVariantName)
                              .join(", ");
                            return (
                              <ResourceItem
                                key={purchasable.shopifyId || ""}
                                id={purchasable.shopifyId || ""}
                                verticalAlignment="center"
                                onClick={() => undefined}
                              >
                                <LegacyStack alignment="center" distribution="equalSpacing">
                                  <div onClick={() => setOpen(open => !open)}>
                                    <LegacyStack alignment="center" distribution="leading">
                                      <ChevronContainer>
                                        <Icon color="base" source={open ? ChevronUpMinor : ChevronDownMinor} />
                                        <Thumbnail
                                          size="small"
                                          source={purchasable.purchasableImages?.[0] || ""}
                                          alt={purchasable.purchasableName}
                                        />
                                      </ChevronContainer>
                                      <ProductAndVariantTitleContainer>
                                        <VariantOrProductTitle
                                          variation="strong"
                                          title={purchasable.purchasableName}
                                        >
                                          {purchasable.purchasableName}
                                        </VariantOrProductTitle>
                                        <VariantOrProductTitle title={variantNames}>
                                          {variantNames}
                                        </VariantOrProductTitle>
                                      </ProductAndVariantTitleContainer>
                                    </LegacyStack>
                                  </div>
                                  <LegacyStack distribution="equalSpacing" spacing="baseTight">
                                    <StatusBadge obj={purchasable} />
                                    <Button
                                      disabled={isProductOrVariantLinkedLoading}
                                      onClick={() =>
                                        handleRemoveProductsAndVariants(
                                          purchasable.vnts?.map(vnt => vnt.shopifyId!) || [],
                                          purchasable.shopifyId!
                                        )
                                      }
                                      plain
                                      icon={CancelSmallMinor}
                                    />
                                  </LegacyStack>
                                </LegacyStack>
                                <Collapsible
                                  open={open}
                                  id="basic-collapsible"
                                  transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
                                  expandOnPrint
                                >
                                  <ResourceList
                                    items={
                                      purchasable.vnts?.filter(vnt => variantIds.includes(vnt.shopifyId!)) || []
                                    }
                                    renderItem={(variant, _, index) => {
                                      const vntName = isDefaultVariant(variant.purchasableVariantName)
                                        ? purchasable.purchasableName
                                        : variant.purchasableVariantName;
                                      return (
                                        <ResourceItemContainer>
                                          <ResourceItem
                                            key={variant.shopifyId || ""}
                                            id={variant.shopifyId || ""}
                                            verticalAlignment="center"
                                            onClick={() => undefined}
                                          >
                                            <LegacyStack alignment="center" distribution="equalSpacing">
                                              <div onClick={() => setOpen(open => !open)}>
                                                <LegacyStack alignment="center" distribution="leading">
                                                  <span>{renderIndexForSubVariant(productIndex, index)}</span>
                                                  <Thumbnail
                                                    size="small"
                                                    source={
                                                      getVariantImageFromPurchasableMap(
                                                        variantToPurchasableMap,
                                                        variant
                                                      )!
                                                    }
                                                    alt={variant?.purchasableVariantName || ""}
                                                  />
                                                  <TitleForLinkedToProductVariants title={vntName}>
                                                    {vntName}
                                                  </TitleForLinkedToProductVariants>
                                                </LegacyStack>
                                              </div>
                                              <LegacyStack distribution="equalSpacing" spacing="baseTight">
                                                <StatusBadge obj={variant} />
                                                <Button
                                                  disabled={isProductOrVariantLinkedLoading}
                                                  onClick={() =>
                                                    handleRemoveProductsAndVariants(
                                                      [variant.shopifyId!],
                                                      purchasable.shopifyId
                                                    )
                                                  }
                                                  plain
                                                  icon={CancelSmallMinor}
                                                />
                                              </LegacyStack>
                                            </LegacyStack>
                                          </ResourceItem>
                                        </ResourceItemContainer>
                                      );
                                    }}
                                  />
                                </Collapsible>
                              </ResourceItem>
                            );
                          }}
                        />
                        <ResourceList
                          items={linkedVariantsToPurchasable}
                          renderItem={(variant, id, index) => (
                            <ResourceItem
                              key={variant.shopifyId || ""}
                              id={variant.shopifyId || ""}
                              verticalAlignment="center"
                              onClick={() => undefined}
                            >
                              <LegacyStack alignment="center" distribution="equalSpacing">
                                <LegacyStack alignment="center" distribution="leading">
                                  <span>
                                    {lastIndex + flatten(linkedProducts.map(p => p.vnts)).length + index + 1}.
                                  </span>
                                  <Thumbnail
                                    size="small"
                                    source={getVariantImageFromPurchasableMap(variantToPurchasableMap, variant)!}
                                    alt={variant?.purchasableVariantName || ""}
                                  />
                                  <VariantOrProductTitle
                                    variation="strong"
                                    title={`${variant.purchasableName} - ${variant.purchasableVariantName}`}
                                  >
                                    {variant.purchasableName} - {variant.purchasableVariantName}
                                  </VariantOrProductTitle>
                                </LegacyStack>
                                <LegacyStack distribution="equalSpacing" spacing="baseTight">
                                  <StatusBadge obj={variant} />
                                  <Button
                                    disabled={isProductOrVariantLinkedLoading}
                                    onClick={() => handleRemoveProductsAndVariants([variant.shopifyId!])}
                                    plain
                                    icon={CancelSmallMinor}
                                  />
                                </LegacyStack>
                              </LegacyStack>
                            </ResourceItem>
                          )}
                        />
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </Scrollable>
            </LegacyCard.Section>
            {!reloadProductsPagination && (!!linkedProducts.length || !!linkedVariantIds.length) && (
              <LegacyCard.Section>
                <PaginationContainer>
                  <div>
                    <CursorPagination
                      label="Results"
                      queryNodes={paginatedVariantsQuery}
                      hasNext={hasNextProductsAndVariantsPage}
                      currentPage={currentPage}
                      setCurrentPage={handleSettingCurrentPage}
                    />
                  </div>
                </PaginationContainer>
              </LegacyCard.Section>
            )}
          </LegacyCard>
        </React.Fragment>
      ) : (
        <LegacyCard sectioned>
          <Box
            vertical
            gap={1}
            py={6}
            px={1}
            alignItems="center"
            justifyContent="center"
            style={{ textAlign: "center" }}
          >
            <Text variant="headingLg" as="p">
              You don&#39;t have any portal add-ons yet.
            </Text>
            <Text variant="bodyMd" as="span" color="subdued">
              Want to add one?
            </Text>
            {isSaving ? (
              <LegacyStack distribution="center" alignment="center">
                <Spinner />
              </LegacyStack>
            ) : (
              <Button primary onClick={onSave}>
                Create portal add-on
              </Button>
            )}
          </Box>
        </LegacyCard>
      )}
      <BrowseProductsModal
        purchasableOptions={purchasableOptions.slice(
          pageNumber * MAX_PAGE_SIZE,
          pageNumber * MAX_PAGE_SIZE + MAX_PAGE_SIZE
        )}
        selectedProductsAndVariants={modalSelectedProductOrVariantIds}
        searchText={filterProductsText}
        title={
          <LegacyStack vertical spacing="extraTight">
            <Text variant="headingLg" as="p">
              Browse products
            </Text>
            <p style={{ fontSize: "14px", lineHeight: "20px" }}>
              Select one or multiple products/variants to be included in as part of product add-ons
            </p>
          </LegacyStack>
        }
        open={showProductOptionsModal}
        onClose={() => {
          setShowProductOptionsModal(false);
          setFilterProductsText("");
        }}
        primaryAction={useMemo(
          () => ({
            content: "Confirm",
            onAction: onEditProductsOrVariantsCb,
            loading: isProductOrVariantLinkedLoading,
          }),
          [onEditProductsOrVariantsCb, isProductOrVariantLinkedLoading]
        )}
        secondaryActions={[
          {
            content: "Cancel",
            onAction() {
              setShowProductOptionsModal(false);
              setFilterProductsText("");
            },
          },
        ]}
        footer={
          <PaginationContainer>
            <Pagination
              hasNext={totalPages > pageNumber + 1}
              hasPrevious={pageNumber != 0}
              label={`Showing ${pageNumber + 1} of ${totalPages || 1}`}
              onNext={() => setPageNumber(prev => prev + 1)}
              onPrevious={() => setPageNumber(prev => prev - 1)}
            />
          </PaginationContainer>
        }
        searchSuffix={
          !filterProductsText && (
            <Button onClick={selectAllProducts} plain>
              {modalSelectedProductOrVariantIds.length === allProductsAndVariantIds.length
                ? "deselect all"
                : "or select all"}
            </Button>
          )
        }
        onSearchChange={text => {
          setFilterProductsText(text);
          //reset page on filter change to avoid blank card
          setPageNumber(0);
        }}
        setSelectedProductAndVariantIds={setModalSelectedProductOrVariantIds}
      />
    </LegacyStack>
  );
}
