import {
  AddonSellingPlanGroupUpdateParams,
  SellingPlanGroupApiResponse,
} from "@smartrr/shared/entities/SellingPlanGroup";
import { captureException } from "@smartrr/shared/utils/captureException";
import { StateCreator, create } from "zustand";
import { devtools } from "zustand/middleware";

import { fullShopifyIdsToNumericIds, numericIdsToFullShopifyIds } from "@vendor-app/utils/numericIdsMapper";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";
import { SellingPlanPricingPolicyInput } from "@smartrr/shared/shopifyGraphQL/api";
import { validatePricingPolicyWithZod } from "../utils";

interface AddonSellingPlanGroupStore {
  addonGroup: SellingPlanGroupApiResponse | null;
  isLoading: boolean;
  status: { message: string; isError: boolean } | null;
  actions: {
    clearStatus(): void;
    load(): Promise<void>;
    create(): Promise<void>;
    update(params: AddonSellingPlanGroupUpdateParams): Promise<void>;
  };
  internalActions: {
    asyncAddonGroupAction<Type>(actionName: string, fn: () => Promise<Type>): Promise<Type>;
  };
}

type AddonSellingPlanGroupStateCreator = StateCreator<AddonSellingPlanGroupStore, [["zustand/devtools", never]]>;

const addonSellingPlanGroupStateCreator: AddonSellingPlanGroupStateCreator = (set, get) => ({
  addonGroup: null,
  isLoading: true,
  status: null,
  actions: {
    clearStatus(): void {
      set(
        {
          status: null,
        },
        false,
        "clearStatus"
      );
    },
    async load(): Promise<void> {
      const ACTION_NAME = "load";
      return get().internalActions.asyncAddonGroupAction(ACTION_NAME, async () => {
        const result = await typedFrontendVendorApi.getReq("/selling-plan-groups/addon");
        if (result.type === "error") {
          if (result.status === 404) {
            set(
              {
                addonGroup: null,
              },
              false,
              ACTION_NAME
            );
            return;
          }

          set(
            {
              status: { message: result.message, isError: true },
            },
            false,
            ACTION_NAME
          );
          captureException("Failed to load add-on configuration", result);
          return;
        }

        const productIds = numericIdsToFullShopifyIds(result.body.productIds, "Product");
        const productVariantIds = numericIdsToFullShopifyIds(result.body.productVariantIds, "ProductVariant");

        set(
          {
            addonGroup: {
              ...result.body,
              productIds,
              productVariantIds,
            },
          },
          false,
          ACTION_NAME
        );
      });
    },
    async create(): Promise<void> {
      const ACTION_NAME = "create";
      return get().internalActions.asyncAddonGroupAction(ACTION_NAME, async () => {
        const result = await typedFrontendVendorApi.postReq("/selling-plan-groups/addon");
        if (result.type === "error") {
          set(
            {
              status: { message: result.message, isError: true },
            },
            false,
            ACTION_NAME
          );
          captureException("Failed to create add-on configuration", result);
          return;
        }

        const productIds = numericIdsToFullShopifyIds(result.body.productIds, "Product");
        const productVariantIds = numericIdsToFullShopifyIds(result.body.productVariantIds, "ProductVariant");

        set(
          {
            addonGroup: {
              ...result.body,
              productIds,
              productVariantIds,
            },
            status: { message: "Add-on configuration created", isError: false },
          },
          false,
          ACTION_NAME
        );
      });
    },
    async update(params: AddonSellingPlanGroupUpdateParams): Promise<void> {
      const ACTION_NAME = "update";

      const policyValidationRes = validatePricingPolicyWithZod(
        params.sellingPlans[0].pricingPolicies as SellingPlanPricingPolicyInput[]
      );

      if (!policyValidationRes.success && policyValidationRes.error) {
        set(
          {
            status: { message: policyValidationRes.error, isError: true },
          },
          false,
          ACTION_NAME
        );
        return;
      }

      return get().internalActions.asyncAddonGroupAction(ACTION_NAME, async () => {
        const result = await typedFrontendVendorApi.putReq("/selling-plan-groups/addon", {
          reqBody: {
            ...params,
            productIds: fullShopifyIdsToNumericIds(params.productIds ?? []),
            productVariantIds: fullShopifyIdsToNumericIds(params.productVariantIds ?? []),
          },
        });
        if (result.type === "error") {
          set(
            {
              status: { message: result.message, isError: true },
            },
            false,
            ACTION_NAME
          );
          captureException("Failed to update add-on configuration", result);
          return;
        }

        const productIds = numericIdsToFullShopifyIds(result.body.productIds, "Product");
        const productVariantIds = numericIdsToFullShopifyIds(result.body.productVariantIds, "ProductVariant");

        set(
          {
            addonGroup: {
              ...result.body,
              productIds,
              productVariantIds,
            },
            status: { message: "Add-on configuration updated", isError: false },
          },
          false,
          ACTION_NAME
        );
      });
    },
  },
  internalActions: {
    async asyncAddonGroupAction<Type>(actionName: string, fn: () => Promise<Type>): Promise<Type> {
      set(
        {
          isLoading: true,
        },
        false,
        actionName
      );

      const response = await fn();

      set(
        {
          isLoading: false,
        },
        false,
        actionName
      );

      return response;
    },
  },
});

const useAddonsSellingPlanGroupStore = create<AddonSellingPlanGroupStore>()(
  devtools(addonSellingPlanGroupStateCreator, { name: "useAddonsSellingPlanGroupStore" })
);

export const AddonSellingPlanGroupStoreAccess = {
  useLoading() {
    return useAddonsSellingPlanGroupStore(state => state.isLoading);
  },
  useAddonGroup() {
    return useAddonsSellingPlanGroupStore(state => state.addonGroup);
  },
  useStatus() {
    return useAddonsSellingPlanGroupStore(state => state.status);
  },
  useActions() {
    return useAddonsSellingPlanGroupStore(state => state.actions);
  },
};
