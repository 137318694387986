import { Pagination } from "@shopify/polaris";
import { last } from "lodash";
import React, { useEffect, useRef, useState } from "react";

interface Props {
  queryNodes: (newCursor: any) => Promise<{ lastCursor: any; hasNextPage: boolean | undefined }>;
  hasNext: boolean;
  label?: string;
  currentPage?: number;
  setCurrentPage?: (page: number) => void;
}

export function CursorPagination({
  queryNodes,
  hasNext,
  setCurrentPage,
  currentPage,
  label,
}: Props): JSX.Element {
  const [showPreviousPage, setShowPreviousPage] = useState(false);
  const [showNextPage, setShowNextPage] = useState(true);
  const [isMovingForward, setIsMovingForward] = useState(true);
  const previousCursors = useRef<Array<any>>([]);
  useEffect(() => {
    setShowNextPage(hasNext);
  }, []);

  const setPreviousCursors = (cursors: Array<any>) => {
    previousCursors.current = cursors;
  };

  const changePage = async (cursor: any) => {
    const { lastCursor, hasNextPage } = await queryNodes(cursor);
    setShowNextPage(hasNextPage || false);

    return {
      cursor: lastCursor,
    };
  };

  const setPreviousPage = async () => {
    if (setCurrentPage && currentPage != undefined) {
      setCurrentPage(currentPage - 1);
    }
    if (previousCursors.current.length) {
      if (isMovingForward) {
        previousCursors.current.pop();
      }
      previousCursors.current.pop();
      if (previousCursors.current.length) {
        setShowPreviousPage(true);
      } else {
        setShowPreviousPage(false);
      }
      // calling the changePage function with null
      // will return the very first page
      const nextCursor = last(previousCursors.current) || null;
      setPreviousCursors(previousCursors.current);

      await changePage(nextCursor);
      setIsMovingForward(false);
    } else {
      setShowPreviousPage(false);
    }
  };

  const setNextPage = async () => {
    if (setCurrentPage && currentPage != undefined) {
      setCurrentPage(currentPage + 1);
    }
    let cursor = last(previousCursors.current);

    if (!cursor) {
      // when we call it with null it should return the very first page
      const pageInfo = await queryNodes(null);
      cursor = pageInfo.lastCursor;
    }

    let nextCursor = (await changePage(cursor)).cursor;
    if (!isMovingForward && previousCursors.current.length !== 0) {
      previousCursors.current.push(nextCursor);
      nextCursor = (await changePage(nextCursor)).cursor;
    }
    setIsMovingForward(true);
    setShowPreviousPage(true);

    if (!previousCursors.current.length) {
      previousCursors.current.push(cursor);
    }
    previousCursors.current.push(nextCursor || "");
    setPreviousCursors(previousCursors.current);
  };

  return (
    <Pagination
      label={label}
      hasPrevious={showPreviousPage}
      onPrevious={setPreviousPage}
      hasNext={showNextPage}
      onNext={setNextPage}
    />
  );
}

export default CursorPagination;
