import { Button, LegacyCard, LegacyStack, Spinner, Text } from "@shopify/polaris";
import { Box } from "@smartrr/shared/components/primitives";
import { ISellingPlanFromZodWithId } from "@smartrr/shared/entities/SellingPlanGroup";
import React, { useEffect } from "react";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { AddonSellingPlanGroupStoreAccess } from "@vendor-app/app/AdminRoute/AdminSellingPlanGroupsRouteFeatureFlagged/store/addon";

import { AddOnGroupContainer } from "./AddonSellingPlan";

function AddonGroupCreate() {
  const actions = AddonSellingPlanGroupStoreAccess.useActions();

  return (
    <LegacyCard sectioned>
      <Box
        vertical
        gap={1}
        py={6}
        px={1}
        alignItems="center"
        justifyContent="center"
        style={{ textAlign: "center" }}
      >
        <Text variant="headingLg" as="p">
          You don&#39;t have any portal add-ons yet.
        </Text>
        <Text variant="bodyMd" as="span" color="subdued">
          Want to add one?
        </Text>

        <Button primary onClick={actions.create}>
          Create portal add-on
        </Button>
      </Box>
    </LegacyCard>
  );
}

export function AddOnGroupContainerData(): React.JSX.Element {
  const { addToast } = useToast();

  const addonGroup = AddonSellingPlanGroupStoreAccess.useAddonGroup();
  const isLoading = AddonSellingPlanGroupStoreAccess.useLoading();
  const status = AddonSellingPlanGroupStoreAccess.useStatus();
  const actions = AddonSellingPlanGroupStoreAccess.useActions();

  useEffect(() => {
    void actions.load();
  }, [actions]);

  useEffect(() => {
    if (status) {
      addToast(status.message, status.isError);
      actions.clearStatus();
    }
  }, [status, addToast, actions]);

  if (isLoading) {
    return (
      <LegacyStack vertical alignment="center">
        <Spinner />
      </LegacyStack>
    );
  }

  if (!addonGroup) {
    return <AddonGroupCreate />;
  }

  return (
    <AddOnGroupContainer
      sellingPlanGroup={addonGroup}
      onUpdateAddonConfig={async (
        sellingPlan: ISellingPlanFromZodWithId,
        productIds: string[],
        variantIds: string[]
      ) => {
        void actions.update({
          productIds,
          productVariantIds: variantIds,
          sellingPlans: [sellingPlan],
        });
      }}
    />
  );
}
